import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {useRead} from "../../hooks/realtime";
import {Pickup} from "../../models/Pickup";
import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {escapeEmail} from "../../utils/textUtils";
import TextField from "@mui/material/TextField";
import PlusIcon from "@mui/icons-material/PlusOne";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {InputAdornment} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


export default function () {
    const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
    const [tab, setTab] = useState<'all'|'seoul' |'busan'|'tokyo'|'osaka'>('all');
    const {data: pickups, put, save, update} = useRead<{ [area: string]: { [pickup: string]: Pickup } }>('/pickup', loadKey);
    const areas = Object.entries(pickups ?? {});


    return (
        <Box>
            <Tabs value={tab} onChange={(_,t)=>setTab(t)}>
                <Tab value={'all'} label={'ALL'}/>
                <Tab value={'seoul'} label={'SEOUL'}/>
                <Tab value={'busan'} label={'BUSAN'}/>
                <Tab value={'tokyo'} label={'TOKYO'}/>
                <Tab value={'osaka'} label={'OSAKA'}/>
            </Tabs>
            <Box
                display={'flex'}
                flexDirection={'row-reverse'}
                pb={2}
            >
                <Button variant={'contained'} onClick={() => {
                    save()
                        .then(() => {
                            setLoadKey(Date.now() + '');
                        })
                        .catch((e) => {
                            alert('Error on save')
                        });
                }}>
                    Save
                </Button>
            </Box>


            {
                areas.filter(([area])=>{
                    return tab ==='all' || area.toLowerCase().includes(tab);
                }).map(([area, pickup]) => {
                    const pickupList = Object.entries(pickup);
                    return (
                        <Box key={area} pb={8}>
                            <Typography variant={'h4'}>
                                {area.toUpperCase()}
                            </Typography>
                            <Box my={4}>
                                <Stack
                                    gap={4}
                                >
                                    {
                                        pickupList.map(([pk, p]) => {
                                            return (
                                                <Box>
                                                    <Typography variant={'h5'}>
                                                        {p.place}
                                                    </Typography>
                                                    <Stack
                                                        mt={2}
                                                        gap={1}
                                                    >
                                                        {
                                                            (p.possibles ?? []).map((possible, idx) => {
                                                                return (
                                                                        <TextField
                                                                            key={'possibles-' + idx}
                                                                            fullWidth
                                                                            style={{
                                                                                backgroundColor: 'white'
                                                                            }}
                                                                            value={possible}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                const newArray = [...p.possibles]
                                                                                newArray.splice(idx, 1, value);
                                                                                update(area, {...pickup, [pk]: {...p, possibles: newArray}})
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            size={'large'}
                                                                                            onClick={()=>{
                                                                                                const newArray = [...p.possibles]
                                                                                                newArray.splice(idx,1);
                                                                                                update(area, {...pickup, [pk]: {...p, possibles: newArray}})
                                                                                            }}
                                                                                        >
                                                                                            <DeleteIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                )
                                                            })
                                                        }
                                                        <Button
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: 'white',
                                                            }}
                                                            variant={'outlined'}

                                                            onClick={(e) => {
                                                                const newArray = [...p.possibles, ''];
                                                                update(area, {...pickup, [pk]: {...p, possibles: newArray}})
                                                            }}
                                                        >
                                                            <PlusIcon/>
                                                        </Button>
                                                    </Stack>
                                                </Box>
                                            )
                                        })
                                    }
                                </Stack>
                                <Box
                                    sx={(theme) => ({
                                        my: 4,
                                        borderRadius: 2,
                                        border: `solid 1px ${theme.palette.secondary.main}`
                                    })}
                                >
                                    <Button
                                        fullWidth
                                        color={'secondary'}
                                        variant={'text'}
                                        size={'large'}
                                        onClick={e => {
                                            const newPickup = window.prompt('새 픽업지 이름을 영문으로 입력해주세요. 3글자 이상');
                                            if (!newPickup || newPickup.length < 2) return;
                                            const capitalized = newPickup[0].toUpperCase() + newPickup.slice(1, newPickup.length);
                                            update(area, {
                                                ...pickup, [newPickup]: {
                                                    place: capitalized,
                                                    order: pickupList.length,
                                                    possibles: [''],
                                                }
                                            })
                                        }}
                                    >
                                        새 픽업지 추가
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}
