import React, {useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import {readWithKeyStartEndAt} from "../../../hooks/firebase";
import {Operation, Team} from "../../../models/Operation";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";


const VEHICLE_TYPES = ['스타리아', '쏠라티', '렌터카'];
const BUS_TYPES = ['카운티', '28인승', '45인승'];
const OWN_CAR_NUMBERS = ['스타리아 7528', '스타리아 7508', '스타리아 8889', '스타리아 8217', '쏠라티 4817', '쏠라티 4818'];
const MAX_VEHICLE_COUNT = 8;


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (

    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

export default function VehicleCounts() {
  const [area, setArea] = useState<string>('seoul');
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>(getThisWeekRange());
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>(dateRange);
  const formatDateRanges = dateRange.map(djs => (djs ?? dayjs()).format('YYYY-MM-DD'));
  const [ownCarNumbers, setOwnCarNumbers] = useState<string[]>([...OWN_CAR_NUMBERS]);
  const [operations, setOperations] = useState<{ [formattedDate: string]: Operation } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const headerProps = useMemo(() => {
    return VEHICLE_TYPES.flatMap(vehicle => {
      const owned = ownCarNumbers.filter(num => num.includes(vehicle));
      const rented = MAX_VEHICLE_COUNT - owned.length;
      return [...owned, ...Array(rented).fill('렌트카').map((v, idx) => `${vehicle}-${v}${idx + 1}`)];
    })
  }, ownCarNumbers);

  const rows = useMemo(() => {
    return countUpToVehicleRow(countUp(aggregation(operations ?? {}, area), ownCarNumbers), headerProps);
  }, [area, operations, ownCarNumbers, headerProps]);

  const totalVehicleCount = useMemo(() => {
    return rows.reduce((acc, row) => {
      headerProps.forEach((_, idx) => {
        const vehicleNumb = row[idx + 1] ? 1 : 0;
        acc[idx + 1] = (acc[idx + 1] ?? 0) + vehicleNumb;
        acc[0] = (acc[0] ?? 0) + vehicleNumb;
      })
      return acc;
    }, [] as number[]);
  }, [rows]);

  useEffect(() => {
    readWithKeyStartEndAt<Operation>('/operation', formatDateRanges[0], formatDateRanges[1])
      .then((operations) => {
        setOperations(operations);
      });
  }, formatDateRanges)

  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  }
  const handleConfirmDateRange = () => {
    setDateRange(tempDateRange);
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={(theme) => ({
              display: 'flex',
              gap: '16px',
              justifyContent: 'space-between',
              color: theme.palette.text.primary,
              padding: '8px !important',
            })}>
              <Tabs onChange={(_, value) => setArea(value)} value={area}>
                <Tab value={'seoul'} label={'SEOUL'}/>
                <Tab value={'busan'} label={'BUSAN'}/>
                <Tab value={'tokyo'} label={'TOKYO'}/>
                <Tab value={'osaka'} label={'OSAKA'}/>
              </Tabs>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Autocomplete
                  multiple
                  fullWidth
                  options={[]}
                  freeSolo
                  value={ownCarNumbers}
                  onChange={(event, newValue) => {
                    setOwnCarNumbers(newValue);
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({index})}
                        onDelete={() => {
                          setOwnCarNumbers(ownCarNumbers.filter((num) => num !== option));
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="회사 소속 차"
                      placeholder="4자리 차량번호를 입력하세요"
                    />
                  )}
                />
              </Box>
              <Box
                display={'flex'}
                gap={1}
              >
                <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
                {
                  loading ?
                    <CircularProgress/>
                    : <Button onClick={handleConfirmDateRange} variant="contained"
                              disabled={dateRange.toString() === tempDateRange.toString()}>
                      Confirm
                    </Button>

                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    {
                      ['', ...headerProps].map(header => (
                        <TableCell key={header}>
                          {header}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {totalVehicleCount.map((count, idx) => (
                      <TableCell key={idx}>{count}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows.map((row, idx) => (
                      <TableRow key={idx}>
                        {row.map((cell, idx) => (
                          <TableCell key={idx}>{cell}</TableCell>
                        ))}
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

      </Grid>

    </Box>
  )
}


function getThisWeekRange(): [Dayjs, Dayjs] {
  const firstDayofMonth = dayjs().subtract(1, 'month').startOf('month');
  const lastDayofMonth = dayjs().subtract(1, 'month').endOf('month');
  return [firstDayofMonth, lastDayofMonth];
}

type TeamBase = {
  id: string,
  area: string,
  date: string,
  product: string,
  teamName: string,
  guides: string[],
  people: number,
  definedVehicle?: string,
  vehicleNumber?: string,
  mergedTeam?: string,
}

type TeamMerged = TeamBase & {
  mergingTeams?: TeamBase[],
}

type TeamVehicleDetermined = TeamMerged & {
  vehicle: string,
}


function aggregation(operations: { [id: string]: Operation }, area: string = 'seoul') {
  const teamsMap: { [teamId: string]: TeamBase } = {};
  const mergedTeamsMap: { [teamId: string]: TeamMerged } = {};

  Object.entries(operations)
    .forEach(([operationId, operation]) => {
      const tours = operation.tours ?? {};
      Object.entries(tours).filter(([tourId, tour]) => tour.area.toLowerCase().includes(area)).forEach(([tourId, tour]) => {
        const teams = tour.teams ?? {};
        Object.values(teams).forEach((team, idx, teams) => {
          const area = tour.area;
          const product = tour.product;
          const date = operationId;
          const id = team.id;
          const dispatch = team.dispatch;
          const guides = team.guides?.map((g) => `${g.name}`) ?? [];
          const reservations = team.reservations ?? {};
          const people = Object.values(reservations).reduce((result, reservation) => result + reservation.adult + reservation.kid + reservation.infant, 0);
          const vehicle = dispatch?.vehicleModel;
          const vehicleNumber = dispatch?.vehicleNumber;
          const mergedTeam = dispatch?.mergeTeam;
          teamsMap[id] = {
            id,
            area,
            product,
            date,
            teamName: product + (teams.length > 0 ? (idx + 1) : ''),
            guides,
            definedVehicle: vehicle,
            vehicleNumber,
            people,
            mergedTeam
          };
        })
      })
    });

  Object.values(teamsMap).forEach((teamBase) => {
    if (mergedTeamsMap[teamBase.id]) return;
    if (teamBase.mergedTeam) {
      if (!mergedTeamsMap[teamBase.mergedTeam]) {
        mergedTeamsMap[teamBase.mergedTeam] = {...teamsMap[teamBase.mergedTeam]};
      }
      if (!mergedTeamsMap[teamBase.mergedTeam].mergingTeams) mergedTeamsMap[teamBase.mergedTeam].mergingTeams = [];
      mergedTeamsMap[teamBase.mergedTeam].mergingTeams!.push(teamBase);
      return;
    }
    mergedTeamsMap[teamBase.id] = teamBase;
  });

  const vehicleDeterminedTeams: TeamVehicleDetermined[] = Object.values(mergedTeamsMap).map((team) => {
    const teamPeople = team.people;
    const mergingTeamPeople = team.mergingTeams?.map(team => team.people).reduce((a, b) => a + b) ?? 0;
    const people = teamPeople + mergingTeamPeople;
    const area = team.area;
    const product = team.product;
    const suspectedVehicle = area.toLowerCase() === 'seoul' ? determineVehicleTypeSeoul(people, product)
      : area.toLowerCase() === 'busan' ? determineVehicleTypeBusan(people, product)
        : area.toLowerCase() === 'tokyo' ? determineVehicleTypeTokyo(people, product)
        : area.toLowerCase() === 'osaka' ? determineVehicleTypeOsaka(people, product)
          : ''
    const vehicleDetermined = team.definedVehicle ?? suspectedVehicle;
    return {
      ...team, vehicle: vehicleDetermined
    }
  });

  return vehicleDeterminedTeams;

}

function countUp(determines: TeamVehicleDetermined[], ownCarNumbers: string[]) {
  const counts: { [date: string]: { [vehicle: string]: string[] } } = {};
  determines.forEach((determine) => {
    const date = determine.date;
    const vehicle = determine.vehicle;
    const vehicleNumber = /\d{4}/.exec(determine.vehicleNumber ?? '')?.[0] ?? '';
    const vehicleIdentification = (vehicleNumber) ? `${vehicle} ${vehicleNumber}` : `${vehicle} 렌트카`;
    const vehicleId = ownCarNumbers.includes(vehicleIdentification) ? vehicleIdentification : vehicle;
    const teamName = determine.teamName;
    if (!counts[date]) counts[date] = {};
    if (!counts[date][vehicleId]) counts[date][vehicleId] = [];
    counts[date][vehicleId].push(teamName);
  });

  return counts;
}

function countUpToVehicleRow(counts: { [date: string]: { [vehicle: string]: string[] } }, headerProps: string[]) {
  console.log(counts, headerProps);
  return Object.entries(counts).map(([date, vehicles]) => {
    return [date, ...headerProps.map(vehicle => {
      console.log(vehicle);
      if (vehicle.includes('렌트카')) {
        const splitted = vehicle.split('-렌트카');
        const vehicleType = splitted[0];
        const idx = Number(splitted[1]) - 1;
        const value = vehicles[vehicleType]?.[idx] ?? '';
        return value;
      }
      return vehicles[vehicle]?.[0] ?? '';
    })];
  })
}

function determineVehicleTypeSeoul(people: number, product?: string): string {
  if (product?.includes('광장')) {
    return ('도보');
  }
  if (people <= 7) return '스타리아'
  if (people <= 15) return ('카운티');
  return ('45인승');
}

function determineVehicleTypeBusan(people: number, product?: string): string {
  if (people <= 7) return ('스타리아');
  if (people <= 15) return ('카운티');
  return ('45인승');
}

function determineVehicleTypeTokyo(people: number, product?: string): string {
  if (people <= 9) return ('Hiace');
  if (people <= 25) return ('27-seater Bus');
  if (people <= 26) return ('28-seater Bus');
  // if (people <= 30) return ('32-seater Bus');
  return ('49-seater Bus');
}
function determineVehicleTypeOsaka(people: number, product?: string): string {
  if (people <= 9) return ('Hiace');
  if (people <= 25) return ('27-seater Bus');
  if (people <= 26) return ('28-seater Bus');
  // if (people <= 30) return ('32-seater Bus');
  return ('49-seater Bus');
}
