import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';

import {
  DataGridPro,
  GridToolbar,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridColDef, GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import UserModal from "../../partials/UserModal";
import {useAuth} from "../../hooks/auth";
import {useListen, useListenPropValueStartAt, useRead, useReadPropValueStartAt} from "../../hooks/realtime";
import {callFunction, logAction, readWithKeyStartEndAt, removeRealtime, updateRealtime} from "../../hooks/firebase";
import {User} from '../../models/User';
import Icon from '@mui/material/Icon';
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOffOutlined";
import NoShowIcon from "@mui/icons-material/NoAccounts";
import NoShowOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import TourIcon from "@mui/icons-material/Tour";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import DriverIcon from "@mui/icons-material/DriveEta";
import DriverOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutline";
import AgentIcon from "@mui/icons-material/SupportAgent";
import AgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import {Operation} from "../../models/Operation";
import {downloadObjectAsJson} from "../../utils/utils";
import dayjs, {Dayjs} from "dayjs";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import CircularProgress from "@mui/material/CircularProgress";
import {Product} from "../../models/Product";
import {GridValidRowModel} from "@mui/x-data-grid/models/gridRows";
import writeXlsxFile from "write-excel-file";


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (
    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}


export default function () {
  const [tab, setTab] = useState<number>(0);
  const {user, auth} = useAuth();
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
  const [modal, setModal] = useState<boolean>(false);
  const {
    data: users,
    setData,
    loading
  } = useReadPropValueStartAt<User>('user', 'level', (auth?.level ?? 999) - 1, loadKey);
  const [userId, setUserId] = useState<string | null>(null);
  const [guideTourMap, setGuideTourMap] = useState<{ [guideId: string]: { product: string, tourDate: string } }>({})
  const {data: products} = useRead<{ [id: string]: Product }>('/product');
  const [dateRange, setDateRanges] = useState<DateRange<Dayjs>>([dayjs().subtract(1, 'week'), dayjs()]);
  const [tempRanges, setTempRanges] = useState<DateRange<Dayjs>>([dayjs().subtract(1, 'week'), dayjs()]);
  const startAt = dateRange[0]?.format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD');
  const endAt = dateRange[1]?.format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD')

  useEffect(() => {
    if (!products) return;
    let lastTour: typeof guideTourMap = {};
    readWithKeyStartEndAt<Operation>('/operation', startAt, endAt)
      .then((operations) => {
        Object.entries(operations)
          .sort(([dateA,], [dateB]) => dateA > dateB ? 1 : -1)
          .map(([tourDate, operation]) => {
            const tours = operation.tours ?? {};
            const tourList = Object.values(tours);
            tourList.map((tour) => {
              const product = products?.[tour.productId]?.name ?? tour.product ?? tour.productId
              const teams = tour.teams ?? {};
              const teamList = Object.values(teams);
              const guideIds = teamList.map(({guides}) => guides?.map(({id}) => id)).flat(1);
              const guideTourDics = Object.fromEntries(guideIds.map((gId) => ([gId, {product, tourDate}])));
              lastTour = {...lastTour, ...guideTourDics}
            })
          })
        setGuideTourMap(lastTour)
      })
  }, [startAt, endAt, products]);


  const userList = Object.values(users ?? {})
    .filter(user => {
      return tab === 6
        ? user.waiting
        : tab === 1
          ? !user.waiting && user.area.toLowerCase() === 'seoul'
          : tab === 2
            ? !user.waiting && user.area.toLowerCase() === 'busan'
            : tab === 3
              ? !user.waiting && user.area.toLowerCase() === 'tokyo'
              : tab === 4
              ? !user.waiting && user.area.toLowerCase() === 'osaka'
              : tab === 5
                ? !user.waiting && user.on
                : tab === 6
                  ? !user.waiting && !user.on
                  : true
    })
    .map((user) => ({
      id: user.id,
      on: user.on,
      lastTourProduct: guideTourMap[user.id]?.product ?? '',
      lastTourDate: guideTourMap[user.id]?.tourDate ?? '',
      area: user.area,
      operator: user.level <= 1.25,
      cs: user.level === 1.25,
      name: user.name,
      nameEn: user.nameEn,
      email: user.email,
      english: user.english,
      chinese: user.chinese,
      japanese: user.japanese,
      korean: user.korean,
      languages: user.languages,
      tel: user.tel,
      license: user.license,
      driver: user.driver,
      level: user.level,
      winterActivity:user.winterActivity,
    }))

  const handleChangeBuilder = (uid: string, prop: string) => (_: React.MouseEvent | React.ChangeEvent<HTMLInputElement>, value: any) => {
    const ref = `/user/${uid}`;
    setData((users) => {
      if (!users) return users;
      const updates = {[prop]: value};
      updateRealtime(ref, updates)
        .catch(console.error)
      return {...users, [uid]: {...users[uid], ...updates}};
    })
  }

  const guideColumns: GridColDef[] = [
    {
      field: 'area',
      headerName: 'Area',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <Chip variant={'outlined'} color={
          params.row.area === 'Busan' ? 'secondary'
            : params.row.area === 'Tokyo' ? 'warning'
            : params.row.area === 'Osaka' ? 'info'
              : 'primary'
        }
                     label={params.row.area}/>
      }
    },
    {field: 'name', headerName: 'Name', minWidth: 100, align: 'center', headerAlign: 'center', editable: true,},
    {
      field: 'nameEn',
      headerName: 'Name(En)',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      editable: true,
    },
    {field: 'lastTourProduct', headerName: 'Last Tour', minWidth: 100, align: 'center', headerAlign: 'center', editable: true,},
    {field: 'lastTourDate', headerName: 'Last Date', minWidth: 100, align: 'center', headerAlign: 'center', editable: true,},
    {field: 'email', headerName: 'Email', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'tel', headerName: 'Tel', minWidth: 200, align: 'center', headerAlign: 'center'},
    {
      field: 'english', headerName: 'English', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.english
          ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
          : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {
      field: 'chinese', headerName: 'Chinese', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.chinese
          ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
          : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {
      field: 'korean', headerName: 'Korean', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.korean
          ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
          : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {
      field: 'japanese', headerName: 'Japanese', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.japanese
          ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
          : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {field: 'languages', headerName: 'Other Languages', minWidth: 200, align: 'center', headerAlign: 'center'},
    {
      field: 'license', headerName: 'Tourist Guide', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.license
          ? <Icon color={'primary'}><TourIcon/></Icon>
          : <Icon color={'disabled'}><TourOutlinedIcon/></Icon>
      }
    },
    {
      field: 'driver',
      headerName: 'Driver',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.driver
          ? <Icon color={params.row.level === 2.5 ? 'error' : 'primary'}><DriverIcon/></Icon>
          : <Icon color={'disabled'}><DriverOutlinedIcon/></Icon>
      }
    },
    {
      field: 'winterActivity',
      headerName: 'Winter Activity',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.winterActivity
          ? <Icon color={params.row.level === 2.5 ? 'error' : 'primary'}><DownhillSkiingIcon/></Icon>
          : <Icon color={'disabled'}><DownhillSkiingIcon/></Icon>
      }
    },
    {
      field: 'on',
      type: 'actions',
      headerName: 'On',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.on ? <ToggleOnIcon color={'primary'} fontSize={'large'}/> :
            <ToggleOffIcon fontSize={'large'}/>}
          onClick={(e) => {
            handleChangeBuilder(params.row.id, 'on')(e, !params.row.on)
          }}
          label={'star'}
        />
      ]
    },

    {
      field: 'operator',
      headerName: 'Operator',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.operator
          ? <Icon color={params.row.cs ? 'error' : 'primary'}><AgentIcon/></Icon>
          : <Icon color={'disabled'}><AgentOutlinedIcon/></Icon>
      }
    },
  ];

  const handleClick: GridEventListener<'rowClick'> = (row, event, context) => {
    setUserId((row as unknown as User).id)
  }
  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempRanges(dr);
  }
  const handleConfirmDateRange = () => {
    setDateRanges(tempRanges);
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
            <Tab label={'All'}/>
            <Tab label={'Seoul'}/>
            <Tab label={'Busan'}/>
            <Tab label={'Tokyo'}/>
            <Tab label={'Osaka'}/>
            <Tab label={'On'}/>
            <Tab label={'Off'}/>
            <Tab label={'Awaiting'}/>
          </Tabs>
          <Box
            display={'flex'}
            gap={1}
          >
            <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
            {
              loading ?
                <CircularProgress/>
                : <Button onClick={handleConfirmDateRange} variant={'contained'}
                          disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempRanges.map(d => d?.format('YYYY-MM-DD')).join()}>
                  Confirm
                </Button>

            }
            <DownloadGrid
              gridColumns={guideColumns}
              gridRows={userList}
            />
          </Box>
        </Stack>
        <DataGridPro
          columns={guideColumns}
          rows={userList}
          onRowClick={handleClick}
        />
      </Box>
      {
        userId
          ? (
            <UserModal
              id={userId}
              onClose={(update) => {
                setUserId(null);
                if (update) {
                  setLoadKey(Date.now() + '');
                }
              }}

            />
          )
          : null
      }
      {
        modal
          ? <Modal open={modal}>
            <div/>
          </Modal>
          : null
      }
    </>
  )
}


function DownloadGrid<R extends GridValidRowModel>(props: {
  gridColumns: GridColDef[],
  gridRows: R[],
}) {
  const {gridColumns, gridRows} = props;
  const [loading, setLoading] = useState(false);
  const schema = gridColumns.map(({field, headerName}) => ({column: headerName, value: (r: any) => r[field]}))
  const handleClick = () => {
    const download = async () => {
      setLoading(true);
      await writeXlsxFile(gridRows, {
        schema,
        fileName: `kint2-users.xlsx`
      }).then(() => {
      })
    }

    download()
      .catch((e) => {
        console.error(e)
        alert('Fail on download excel');
      })
      .finally(() => setLoading(false));
  };

  return (

    <Button onClick={handleClick} variant={"outlined"}>
      {
        loading ? <CircularProgress size={32}/>
          : 'EXCEL'
      }
    </Button>
  )
}
