import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import ButtonBase from '@mui/material/ButtonBase'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'

import PlusOneIcon from '@mui/icons-material/PlusOne'
import NoShowIcon from '@mui/icons-material/NoAccounts'
import NoShowOutlinedIcon from '@mui/icons-material/NoAccountsOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutline'
import StarIcon from '@mui/icons-material/Star'
import StarOutlinedIcon from '@mui/icons-material/StarOutline'
import CloseIcon from '@mui/icons-material/Close'
import MailIcon from '@mui/icons-material/Email'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import MemoIcon from '@mui/icons-material/EditNote'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined'
import LocalCafeIcon from '@mui/icons-material/LocalCafe'

import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro'

import { Team, Tour } from '../models/Operation'
import { User } from '../models/User'
import { useListen, useRead } from '../hooks/realtime'

import TeamButton from '../components/TeamButton'
import ReservationModal from './ReservationModal'
import { Reservation, ReservationBase } from '../models/Reservation'
import { GridEventListener } from '@mui/x-data-grid/models/events'
import {
  callFunction,
  logAction,
  pushKeyRealtime,
  readRealtime,
  removeRealtime,
  updateFireStore,
  updateRealtime, updateValueWithTransaction,
} from '../hooks/firebase'
import Chip from '@mui/material/Chip'
import CreateReservationModal from './CreateReservationModal'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import PeopleIcon from '@mui/icons-material/PeopleOutline'
import ReservationIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { AuthContext } from '../hooks/auth'
import CircularProgress from '@mui/material/CircularProgress'
import { Product } from '../models/Product'
import pickups from '../pages/settings/pickups'
import { update } from 'firebase/database'

type ReservationRow = {
  id: string
  memo: string
  date: string
  product: string
  pickupPlace: string
  people: string
  option: string
  clientName: string
  nationality: string
  language: string
  agency: string
  agencyCode: string
  star: boolean
  check: boolean
  noShow: boolean
  tourId: string
  teamId: string
  teamIdx: number
  stroller: string
  adult: number
  kid: number
  infant: number
  messenger?: string
  email?: string
  tel?: string
  sending: boolean
  sent: boolean
  shuttleSent: boolean,
  isRead: boolean
  isMatched: boolean
  kup: boolean
}

export default function (props: {
  date: string
  tourId: string
  defaultTeam: Team | null
  onClose: () => void
  occupiedGuides: {
    [id: string]: { id: string; tourId: string; teamId: string }
  }
  keyword?: string | null
  products?: any | null
}) {
  const { operator } = useContext(AuthContext)
  const {
    products,
    date,
    defaultTeam,
    tourId,
    onClose,
    occupiedGuides,
    keyword,
  } = props
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null)
  const [team, setTeam] = useState<Team | null>(defaultTeam)
  const [reservationId, setReservationId] = useState<string | null>(null)
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>(
    [])
  const [copyReservation, setCopyReservation] = useState<Reservation | null>(
    null)
  const [sendingReservations, setSendingReservations] = useState<{
    [reservationId: string]: boolean
  }>({})
  const [mailedReservations, setMailedReservations] = useState<{
    [reservationId: string]: boolean
  }>({})
  const [sendingShuttleReservations, setSendingShuttleReservations] = useState<{
    [reservationId: string]: boolean
  }>({})
  const [shuttleMailedReservations, setShuttleMailedReservations] = useState<{
    [reservationId: string]: boolean
  }>({})
  const [sendingMails, setSendingMails] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const { data: guides } = useRead<{ [key: string]: User }>(`/user`) //Todo Limit
  const tourRef = `/operation/${date}/tours/${tourId}`
  const { data: tour, setData: setTour } = useListen<Tour>(tourRef)

  const teams = Object.entries(tour?.teams ?? {}).map(([teamId, team], idx) => {
    team.id = teamId
    team.idx = (idx + 1)
    return team
  }).sort(({ idx: a }, { idx: b }) => a - b)

  const [isOpenChatLoading, setIsOpenChatLoading] = useState(false)

  const [reservationOverrides, setReservationOverrides] = useState<{
    [reservationId: string]: { [property: string]: any }
  }>({})

  const product = products[tourId]
  const area = AREAS[product.area?.toLowerCase() ?? 'seoul']

  const teamPickup = team?.dispatch?.pickup ??
    (reducePickups(Object.values(team?.reservations ?? {}), product).at(0) ??
      null)
  const teamPeople = team?.dispatch?.people ??
    (Object.values(team?.reservations ?? {}).
      map(r => r.adult + r.kid + r.infant)).reduce((a, b) => a + b, 0)
  const teamVehicle = team?.dispatch?.vehicleModel ??
    area.assignVehicle(teamPeople)[0]
  const teamVehicleNumber = team?.dispatch?.vehicleNumber ?? ''
  const teamDriverName = team?.dispatch?.driverName ?? ''
  const teamDriverContact = team?.dispatch?.driverContact ?? ''
  const teamMergeTeam = team?.dispatch?.mergeTeam ?? ''
  const teamDispatchMemo = team?.dispatch?.memo ?? ''

  const hasDefinedDispatch = team?.dispatch &&
    (Object.values(team.dispatch).filter(s => s !== undefined).length > 0)

  const reservations = teams.filter(
    (_team) => (team ? team.id === _team.id : true)).
    reduce((result, _team, idx) => {
      const reservations = Object.entries(_team.reservations ?? {}).
        map<ReservationRow>(([id, r]) => ({
          ...r,
          id,
          product: products?.[tour?.productId ?? '']?.name.toUpperCase() ??
            tour?.productId ?? '',
          clientName: r.clientName?.replace(/\(.+\)/, ''),
          language: (r?.language ?? 'English').split(/- /g)[0].replace(
            /english/gi, 'EN').
            replace(/chinese/gi, 'CN').
            replace(/japanese/gi, 'JP').
            replace(/korean/gi, 'KO'),
          people: `${r.adult + r.kid +
          r.infant}(${r.adult}/${r.kid}/${r.infant})`,
          option: r.option?.map(
            (option) => `${option.option}(${option.people})`).
            sort().
            join(', ') ?? '',
          teamId: _team.id,
          tourId,
          teamIdx: _team.idx,
          stroller: !!r.stroller ? 'O' : 'X',
          sending: (sendingReservations[r.id] || sendingShuttleReservations[r.id] || false),
          sent: (!!r.mailedAt || mailedReservations[r.id]) ?? false,
          shuttleSent: (!!r.shuttleMailedAt || shuttleMailedReservations[r.id]) ?? false,
          isRead: !!(r.readMailAt ?? false),
          kup: r.kup ?? false,
          isMatched: keyword
            ? `${r.clientName}${r.agencyCode}${r.email}${r.tel}${r.messenger}${r.id}${r.memo}`.toLowerCase().
              includes(keyword.toLowerCase())
            : false,
          ...(reservationOverrides[id] ?? {}),
        }))
      result.push(...reservations)
      return result
    }, [] as ReservationRow[])

  const reservationClientNameMap = reservations.reduce(
    (result, reservation) => {
      const clientName = reservation.clientName?.toLowerCase()
      if (result.get(clientName)) result.set(clientName,
        result.get(clientName) + 1)
      else result.set(clientName, 1)
      return result
    }, new Map())

  const selectedReservations = reservations.filter(
    (r) => rowSelectionModel.includes(r.id))

  const guideOptions = Object.values(guides ?? {}).
    filter(({ waiting, on, id, email, name, area, level }) => {
      return !waiting && on &&
        (area.toLowerCase() === tour?.area.toLowerCase() || level <= 1)
    }).
    map(({
      id,
      name,
      nameEn,
      email,
      license,
      driverClass1,
      driverClass2,
      driver,
      english,
      chinese,
      japanese,
      korean,
      winterActivitySki,
      winterActivitySnowboard,
    }) => {
      const color =
        !japanese && (english && chinese) ? 'accentPrimary'
          : !japanese && (!english && chinese) ? 'accentSecondary'
            : japanese && english && chinese ? 'secondary'
              : japanese && english ? 'warning'
                : japanese && korean ? 'success'
                  : japanese && chinese ? 'info'
                    : 'default'
      return {
        id: id,
        value: id,
        color,
        name: `${license ? '(관)' : ''}${driverClass1 ? '(운1)' : driverClass2
          ? '(운2)'
          : driver ? '(운)' : ''}${winterActivitySki && winterActivitySnowboard
          ? '(스보)'
          : winterActivitySki ? '(스)' : winterActivitySnowboard
            ? '(보)'
            : ''}${name}${nameEn ? `(${nameEn})` : ''}` ,
        title: `${license ? '(관)' : ''}${driverClass1 ? '(운1)' : driverClass2
          ? '(운2)'
          : driver ? '(운)' : ''}${winterActivitySki && winterActivitySnowboard
          ? '(스보)'
          : winterActivitySki ? '(스)' : winterActivitySnowboard
            ? '(보)'
            : ''}${name}${nameEn ? `(${nameEn})` : ''}`,
        label: `${license ? '(관)' : ''}${driverClass1 ? '(운1)' : driverClass2
          ? '(운2)'
          : driver ? '(운)' : ''}${winterActivitySki && winterActivitySnowboard
          ? '(스보)'
          : winterActivitySki ? '(스)' : winterActivitySnowboard
            ? '(보)'
            : ''}${name}${nameEn ? `(${nameEn})` : ''}` ,
      }
    })

  const mapToGuideSelections = (teamGuides: { id: string, name: string }[]) => {
    return teamGuides.filter(({ id, name }) => {
      return guides && guides[id]
    }).map(({ id, name }) => {
      if (!guides)
        return {
          id,
          value: id,
          name,
          title: name,
          label: name,
        }
      const guide = guides[id]
      if (!guide)
        return {
          id,
          value: id,
          name,
          title: name,
          label: name,
        }
      const {
        name: nameKr,
        nameEn,
        email,
        license,
        driverClass1,
        driverClass2,
        driver,
        english,
        chinese,
        japanese,
        korean,
        winterActivitySki,
        winterActivitySnowboard,
      } = guide
      const color =
        !japanese && (english && chinese) ? 'accentPrimary'
          : !japanese && (!english && chinese) ? 'accentSecondary'
            : japanese && english && chinese ? 'secondary'
              : japanese && english ? 'warning'
                : japanese && korean ? 'success'
                  : japanese && chinese ? 'info'
                    : 'default'
      return {
        id,
        value: id,
        color,
        name: `${license ? '(관)' : ''}${driverClass1 ? '(운1)' : driverClass2
          ? '(운2)'
          : driver ? '(운)' : ''}${winterActivitySki && winterActivitySnowboard
          ? '(스보)'
          : winterActivitySki ? '(스)' : winterActivitySnowboard
            ? '(보)'
            : ''}${name}${nameEn ? `(${nameEn})` : ''}`,
        title: `${license ? '(관)' : ''}${driverClass1 ? '(운1)' : driverClass2
          ? '(운2)'
          : driver ? '(운)' : ''}${winterActivitySki && winterActivitySnowboard
          ? '(스보)'
          : winterActivitySki ? '(스)' : winterActivitySnowboard
            ? '(보)'
            : ''}${name}${nameEn ? `(${nameEn})` : ''}`,
        label: `${license ? '(관)' : ''}${driverClass1 ? '(운1)' : driverClass2
          ? '(운2)'
          : driver ? '(운)' : ''}${winterActivitySki && winterActivitySnowboard
          ? '(스보)'
          : winterActivitySki ? '(스)' : winterActivitySnowboard
            ? '(보)'
            : ''}${name}${nameEn ? `(${nameEn})` : ''}`,
      }
    }) ?? []
  }

  const teamGuideSelected = mapToGuideSelections(team?.guides?.filter(
    (g) => !team?.apprenticeships?.find(a => a.id === g.id) &&
      !team?.explorations?.find(e => e.id === g.id) &&
      !team?.drivers?.find(e => e.id === g.id)) ?? [])
  const teamApprenticeshipSelected = mapToGuideSelections(
    team?.apprenticeships ?? [])
  const teamExplorationSelected = mapToGuideSelections(
    team?.explorations ?? [])
  const teamDriverSelected = mapToGuideSelections(team?.drivers ?? [])

  const findGuides = (reservationRow: ReservationRow) => {
    const team = tour?.teams?.[reservationRow.teamId]
    const teamGuides = team?.guides ?? []
    return teamGuides.map((g) => g && guides && guides?.[g.id]).
      filter((guide) => guide && guide.level <= 2)
  }

  const clearTourTeamProperties = () => {
    if (!team) return
    const path = `/operation/${date}/tours/${tourId}/teams/${team.id}`
    const clearData = {
      dispatch: null,
    }
    logAction('OPERATION', 'CLEAR DISPATCH', path,
      `Clear all dispatch information on ${path}`,
      { dispatch: team?.dispatch ?? {} })
    updateRealtime(path, clearData).catch(console.error)
    setTeam((team) => {
      if (team === null) return team
      return ({
        ...team,
        dispatch: undefined,
      })
    })
  }

  const handeChangeTourTeamRowProps = (
    property: keyof NonNullable<Team['dispatch']>, value: any) => {
    if (!team) return
    if (!product) return
    const dispatchPath = `/operation/${date}/tours/${tourId}/teams/${team.id}/dispatch`
    switch (property) {
      case 'pickup':
        const changePickup = product?.chat?.pickup?.[value]
        const pickup: Pickup = {
          place: value,
          time: (product?.winter
            ? changePickup?.winterTime
            : changePickup?.time) ?? '99:99',
        }
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.pickup)} to ${JSON.stringify(pickup)}`,
            { pickup })
          updateRealtime(dispatchPath, {
            pickup,
          }).catch(console.error)
          return { ...team, dispatch: { ...(team.dispatch ?? {}), pickup } }
        })
        return
      case 'vehicleModel':
        const [vehicleModel] = checkVehicleType(value)
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.vehicleModel)} to ${JSON.stringify(vehicleModel)}`,
            { vehicleModel })
          updateRealtime(dispatchPath, {
            vehicleModel,
          }).catch(console.error)
          return {
            ...team,
            dispatch: { ...(team.dispatch ?? {}), vehicleModel },
          }
        })
        return
      case 'vehicleNumber':
        const vehicleNumber = value
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.vehicleNumber)} to ${JSON.stringify(
              vehicleNumber)}`, { vehicleNumber })
          updateRealtime(dispatchPath, {
            vehicleNumber,
          }).catch(console.error)
          return {
            ...team,
            dispatch: { ...(team.dispatch ?? {}), vehicleNumber },
          }
        })
        return
      case 'driverName':
        const driverName = value
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.driverName)} to ${JSON.stringify(driverName)}`,
            { driverName })
          updateRealtime(dispatchPath, {
            driverName,
          }).catch(console.error)
          return { ...team, dispatch: { ...(team.dispatch ?? {}), driverName } }
        })
        return
      case 'driverContact':
        const driverContact = value
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.driverContact)} to ${JSON.stringify(
              driverContact)}`, { driverContact })
          updateRealtime(dispatchPath, {
            driverContact,
          }).catch(console.error)
          return {
            ...team,
            dispatch: { ...(team.dispatch ?? {}), driverContact },
          }
        })
        return
      case 'people':
        const people = value
        const peopleVehicle = area.assignVehicle(value)
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.people)} to ${JSON.stringify(people)}`, {
              people,
              peopleVehicle,
            })
          updateRealtime(dispatchPath, {
            people,
            vehicleModel: peopleVehicle[0],
          }).catch(console.error)
          return {
            ...team,
            dispatch: {
              ...(team.dispatch ?? {}),
              people,
              vehicle: peopleVehicle[0],
            },
          }
        })
        return
      case 'memo':
        const memo = value
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.memo)} to ${JSON.stringify(memo)}`, { memo })
          updateRealtime(dispatchPath, {
            memo,
          }).catch(console.error)
          return { ...team, dispatch: { ...(team.dispatch ?? {}), memo } }
        })
        return
      case 'mergeTeam':
        const mergeTeam = value
        setTeam((team) => {
          if (!team || !team.id) return null
          logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath,
            `update ${property} from ${JSON.stringify(
              team.dispatch?.mergeTeam)} to ${JSON.stringify(mergeTeam)}`,
            { mergeTeam })
          updateRealtime(dispatchPath, {
            mergeTeam,
          }).catch(console.error)
          return { ...team, dispatch: { ...(team.dispatch ?? {}), mergeTeam } }
        })
        return
    }
  }

  const handleSelectTeam = (team: Team) => setTeam(team)
  const handleDeleteTeam = (team: Team) => {
    if (!window?.confirm('삭제하시겠습니까?')) return
    removeRealtime(`${tourRef}/teams/${team.id}`).catch(console.error)
    setTeam(null)
  }
  const handleReservationRowClick: GridEventListener<'rowClick'> = (
    row, event, context) => {
    setReservationId((row as unknown as Reservation).id)
  }
  const handleSelectRows = (newRowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
  }

  const handleClickAddTeam = () => {
    pushKeyRealtime(`/operation/${date}/tours/${tourId}/teams`).
      then((newTeamId) => {
        if (!newTeamId) return
        setTour((tour) => {
          if (!tour) return tour
          const newTeam = {
            id: newTeamId,
            idx: Object.values(tour.teams ?? {}).length,
            memo: '',
          }
          const teams = { ...(tour.teams ?? {}), [newTeamId]: newTeam }
          updateRealtime(`/operation/${date}/tours/${tourId}/teams`, teams).
            catch(console.error)
          setTeam(newTeam)
          return { ...tour, teams }
        })
      })
  }

  const handleClickAllTeam = () => {
    setTeam(null)
  }

  const handleChangeHoldTime = (holdTimeDate: Dayjs | null) => {
    if (!holdTimeDate) return console.log('null time')
    setTeam((team) => {
      if (!team || !team.id) return null
      const holdTime = holdTimeDate.toDate().toUTCString()
      updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
        holdTime,
      }).catch(console.error)
      return { ...team, holdTime }
    })
  }

  const handleHoldTimeNow = () => {
    setTeam((team) => {
      if (!team || !team.id) return null
      const holdTime = dayjs().toDate().toUTCString()
      updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
        holdTime,
      }).catch(console.error)
      return { ...team, holdTime }
    })
  }

  const adjustGuideApprenticeshipCount = (
    guideId: string, adjustment: number) => {
    const path = `/user/${guideId}/apprenticeships`
    updateValueWithTransaction(path, (count: number | null) => {
      const temp = (count ?? 0) + adjustment
      return Math.max(temp, 0)
    }).catch((e) => {
      console.error(e)
      alert('수습 횟수를 갱신할 수 없습니다.')
    })
  }

  const changeGuides = (
    event: React.SyntheticEvent, newGuides: { id: string; name: string }[]) => {
    setTeam((team) => {
      if (!team || !team.id) return null
      const updateGuides = newGuides.map(({ id, name }) => {
        const found = guides?.[id] ?? { id, name }
        return { id: found.id, name: found.name }
      })

      const productName = products?.[tourId]?.name ?? tourId
      const teamNumb = teams.findIndex((t) => t.id === team.id) + 1
      const changes = `Guides changed ${date}/${productName}/${team.id}:[${(team.guides ??
        []).map((g) => g.name).join(', ')}]=>[${updateGuides.map(g => g.name).
        join(', ')}]`

      updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
        guides: updateGuides,
      }).then(() => {
        logAction('OPERATION', 'CHANGE GUIDE',
          `${date}/${productName}/${teamNumb}(${team.id})`, changes, {
            date,
            tourId,
            id: team.id,
            guides: updateGuides,
          })
      }).catch(console.error)

      Promise.all(
        newGuides.map(({ id }) => {
          const occupiedGuide = occupiedGuides[id]
          if (!occupiedGuide) return Promise.resolve()
          if (occupiedGuide.teamId === team.id) return Promise.resolve()
          const cleaner = (various: 'guides' | 'apprenticeships' | 'explorations') =>
            readRealtime<{
              id: string;
              name: string
            }[] | null>(
              `/operation/${date}/tours/${occupiedGuide.tourId}/teams/${occupiedGuide.teamId}/${various}`).
              then((exists) => {
                if (exists === null) {
                  return
                }
                return exists.filter(({ id }) => id !== occupiedGuide.id)
              }).
              then((guides) => {
                return updateRealtime(
                  `/operation/${date}/tours/${occupiedGuide.tourId}/teams/${occupiedGuide.teamId}`,
                  { [various]: guides },
                )
              }).
              then(() => {
                const guide = updateGuides.find(
                  ({ id }) => id === occupiedGuide.id)
                if (guide) {
                  setSnackbarMessage(`${guide.name}가 재배치 되었습니다.`)
                }
              }).
              catch(console.error)
          return Promise.all([
            cleaner('guides'),
            cleaner('apprenticeships'),
            cleaner('explorations')])
        }),
      ).catch(console.error)
      return { ...team, guides: updateGuides }
    })
  }

  const handleChangeGuides = (
    event: React.SyntheticEvent, newGuides: { id: string; name: string }[]) => {
    if (!team || !team.id) return null
    const updateApprenticeships = [...team?.apprenticeships ?? []].filter(
      a => !newGuides.find(u => u.id === a.id))
    const updateExplorations = [...team?.explorations ?? []].filter(
      e => !newGuides.find(u => u.id === e.id))
    const updateDrivers = [...team?.drivers ?? []].filter(
      d => !newGuides.find(u => u.id === d.id))

    const subtractedApprenticeships = [...team?.apprenticeships ?? []].filter(
      (a) => !updateApprenticeships.find(u => u.id === a.id))
    if (subtractedApprenticeships.length > 0) {
      subtractedApprenticeships.forEach((sa) => {
        const gId = sa.id
        adjustGuideApprenticeshipCount(gId, -1)
      })
    }

    const updateGuides = newGuides.map(({ id, name }) => {
      const found = guides?.[id] ?? { id, name }
      return { id: found.id, name: found.name }
    }).
      concat(updateApprenticeships).
      concat(updateExplorations).
      concat(updateDrivers)

    updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
      apprenticeships: updateApprenticeships,
      explorations: updateExplorations,
      drivers: updateDrivers,
    }).catch(console.error)
    setTeam(team => {
      if (!team || !team.id) return null
      return {
        ...team,
        apprenticeships: updateApprenticeships,
        explorations: updateExplorations,
        drivers: updateDrivers,
      }
    })
    changeGuides(event, updateGuides)

  }
  // teamApprenticeshipSelected
  const handleChangeApprenticeship = (
    event: React.SyntheticEvent, newApprenticeship: {
      id: string;
      name: string
    }[]) => {
    if (!team || !team.id) return null
    const prev = [...team?.apprenticeships ?? []]
    const updateApprenticeship = newApprenticeship.map(({ id, name }) => {
      const found = guides?.[id] ?? { id, name }
      return { id: found.id, name: found.name }
    })

    const subtractedApprenticeships = [...team?.apprenticeships ?? []].filter(
      (a) => !updateApprenticeship.find(u => u.id === a.id))
    const addedApprenticeships = [...updateApprenticeship].filter(
      (a) => !team.apprenticeships?.find(u => u.id === a.id))

    if (subtractedApprenticeships.length > 0 || addedApprenticeships.length >
      0) {
      subtractedApprenticeships.forEach((sa) => {
        const gId = sa.id
        adjustGuideApprenticeshipCount(gId, -1)
      })
      addedApprenticeships.forEach((sa) => {
        const gId = sa.id
        adjustGuideApprenticeshipCount(gId, +1)
      })
    }

    const productName = products?.[tourId]?.name ?? tourId
    const teamNumb = teams.findIndex((t) => t.id === team.id) + 1
    const changes = `Apprenticeship changed ${date}/${productName}/${team.id}:[${(team.apprenticeships ??
      []).map((g) => g.name).join(', ')}]=>[${updateApprenticeship.map(
      g => g.name).join(', ')}]`

    updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
      apprenticeships: updateApprenticeship,
    }).then(() => {
      logAction('OPERATION', 'CHANGE GUIDE',
        `${date}/${productName}/${teamNumb}(${team.id})`, changes, {
          date,
          tourId,
          id: team.id,
          apprenticeships: updateApprenticeship,
        })
    }).catch(console.error)

    const prevGuideIds = [...team?.apprenticeships ?? []].map(g => g.id)
    const nextGuideIds = [...updateApprenticeship].map(g => g.id)
    const newGuides = (team.guides?.filter(
        (g) => !prevGuideIds.includes(g.id) && !nextGuideIds.includes(g.id)) ??
      []).concat(updateApprenticeship)

    changeGuides(event, newGuides)
    setTeam((team) => {
      if (!team || !team.id) return null
      if (updateApprenticeship.find(
          a => team.explorations?.find(e => e.id === a.id)) ||
        updateApprenticeship.find(
          e => team.drivers?.find(a => a.id === e.id))) {
        const newExplorations = [...team.explorations ?? []].filter(
          e => !updateApprenticeship.find(a => a.id === e.id))
        const newDrivers = [...team.drivers ?? []].filter(
          d => !updateApprenticeship.find(a => a.id === d.id))
        updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
          explorations: newExplorations,
          drivers: newDrivers,
        }).catch(console.error)
        return {
          ...team,
          apprenticeships: updateApprenticeship,
          explorations: newExplorations,
          drivers: newDrivers,
        }
      }
      return { ...team, apprenticeships: updateApprenticeship }
    })
  }
  // teamExplorationSelected
  const handleChangeExploration = (
    event: React.SyntheticEvent, newExplorations: {
      id: string;
      name: string
    }[]) => {
    if (!team || !team.id) return null
    const updateExplorations = newExplorations.map(({ id, name }) => {
      const found = guides?.[id] ?? { id, name }
      return { id: found.id, name: found.name }
    })

    const productName = products?.[tourId]?.name ?? tourId
    const teamNumb = teams.findIndex((t) => t.id === team.id) + 1
    const changes = `Exploration changed ${date}/${productName}/${team.id}:[${(team.explorations ??
      []).map((g) => g.name).join(', ')}]=>[${updateExplorations.map(
      g => g.name).join(', ')}]`

    updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
      explorations: updateExplorations,
    }).then(() => {
      logAction('OPERATION', 'CHANGE GUIDE',
        `${date}/${productName}/${teamNumb}(${team.id})`, changes, {
          date,
          tourId,
          id: team.id,
          explorations: updateExplorations,
        })
    }).catch(console.error)

    const prevGuideIds = [...team?.explorations ?? []].map(g => g.id)
    const nextGuideIds = [...updateExplorations].map(g => g.id)
    const newGuides = (team.guides?.filter(
        (g) => !prevGuideIds.includes(g.id) && !nextGuideIds.includes(g.id)) ??
      []).concat(updateExplorations)

    changeGuides(event, newGuides)
    setTeam((team) => {
      if (!team || !team.id) return null
      if (updateExplorations.find(
          e => team.apprenticeships?.find(a => a.id === e.id)) ||
        updateExplorations.find(e => team.drivers?.find(a => a.id === e.id))) {
        const updateApprenticeships = [...team.apprenticeships ?? []].filter(
          a => !updateExplorations.find(e => e.id === a.id))
        const updateDrivers = [...team.drivers ?? []].filter(
          d => !updateExplorations.find(e => e.id === d.id))
        updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
          apprenticeships: updateApprenticeships,
          drivers: updateDrivers,
        }).catch(console.error)

        const subtractedApprenticeships = [
          ...team?.apprenticeships ?? []].filter(
          (a) => !updateApprenticeships.find(u => u.id === a.id))
        if (subtractedApprenticeships.length > 0) {
          subtractedApprenticeships.forEach((sa) => {
            const gId = sa.id
            adjustGuideApprenticeshipCount(gId, -1)
          })
        }

        return {
          ...team,
          apprenticeships: updateApprenticeships,
          explorations: newExplorations,
          drivers: updateDrivers,
        }
      }
      return { ...team, explorations: updateExplorations }
    })

  }

  // teamExplorationSelected
  const handleChangeDriver = (event: React.SyntheticEvent, newDrivers: {
    id: string;
    name: string
  }[]) => {
    if (!team || !team.id) return null
    const updateDrivers = newDrivers.map(({ id, name }) => {
      const found = guides?.[id] ?? { id, name }
      return { id: found.id, name: found.name }
    })

    const productName = products?.[tourId]?.name ?? tourId
    const teamNumb = teams.findIndex((t) => t.id === team.id) + 1
    const changes = `Driver changed ${date}/${productName}/${team.id}:[${(team.drivers ??
      []).map((g) => g.name).join(', ')}]=>[${updateDrivers.map(g => g.name).
      join(', ')}]`

    updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
      drivers: updateDrivers,
    }).then(() => {
      logAction('OPERATION', 'CHANGE GUIDE',
        `${date}/${productName}/${teamNumb}(${team.id})`, changes, {
          date,
          tourId,
          id: team.id,
          drivers: updateDrivers,
        })
    }).catch(console.error)

    const prevGuideIds = [...team?.drivers ?? []].map(g => g.id)
    const nextGuideIds = [...updateDrivers].map(g => g.id)
    const newGuides = (team.guides?.filter(
        (g) => !prevGuideIds.includes(g.id) && !nextGuideIds.includes(g.id)) ??
      []).concat(updateDrivers)

    changeGuides(event, newGuides)
    setTeam((team) => {
      if (!team || !team.id) return null
      if (updateDrivers.find(
          d => team.apprenticeships?.find(a => a.id === d.id)) ||
        updateDrivers.find(d => team.explorations?.find(a => a.id === d.id))) {
        const updateApprenticeships = [...team.apprenticeships ?? []].filter(
          a => !updateDrivers.find(d => d.id === a.id))
        const updateExplorations = [...team.explorations ?? []].filter(
          e => !updateDrivers.find(d => d.id === e.id))
        updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}`, {
          apprenticeships: updateApprenticeships,
          explorations: updateExplorations,
        }).catch(console.error)
        return {
          ...team,
          apprenticeships: updateApprenticeships,
          explorations: updateExplorations,
          drivers: updateDrivers,
        }
      }
      return { ...team, drivers: updateDrivers }
    })

  }

  const handleChangeTeamMemoBuilder = (key: string) => (event: any) => {
    setTeam((team) => {
      if (!team || !team.id) return null
      const memo = event.target.value

      const productName = products?.[tourId]?.name ?? tourId
      const teamNumb = teams.findIndex((t) => t.id === team.id) + 1
      const changes = `Memo changed ${date}/${productName}/${team.id}:[${team.memo}=>${memo}]`

      updateRealtime(`/operation/${date}/tours/${tourId}/teams/${team.id}/memo`,
        { [key]: memo }).then(() => {
        logAction('OPERATION', 'UPDATE TEAM MEMO',
          `${date}/${productName}/${teamNumb}(${team.id})`, changes, {
            date,
            tourId,
            id: team.id,
            memo: memo,
          })
      }).catch(
        console.error,
      )
      const legacyMemo = typeof team.memo === 'string'
        ? { office: team.memo }
        : team.memo
      return { ...team, memo: { ...legacyMemo, [key]: memo } }
    })
  }

  const handleClickAssignTeamBuilder = (destinationTeamId: string) => () => {
    if (!destinationTeamId) return alert('대상 팀이 존재하지 않습니다.')
    if (!rowSelectionModel?.length) return
    if (!tour) return
    if (!tour.teams) return
    if (!tour.teams[destinationTeamId]) return;
    (async () => {
      for (let teamId in tour.teams) {
        const team = tour.teams[teamId]

        const productName = products?.[tourId]?.name ?? tourId
        const toTeamNumb = teams.findIndex((t) => t.id === destinationTeamId) +
          1
        const fromTeamNumb = teams.findIndex((t) => t.id === team.id) + 1
        const movingReservations = reservations?.filter(
          r => rowSelectionModel.includes(r.id)) ?? []
        const movingReservationsSignatures = movingReservations.map(
          r => `${r.id}(${r.agency}, ${r.agencyCode}})`).join('\r\n')
        const changes = `Team change ${date}/${productName} ${movingReservations.length} reservations:[TEAM${fromTeamNumb}(${team.id}) => TEAM${toTeamNumb}(${destinationTeamId})]\r\n${movingReservationsSignatures}`

        for (let id of rowSelectionModel) {
          if (team.reservations?.[id] && destinationTeamId !== teamId) {
            await removeRealtime(
              `/operation/${date}/tours/${tourId}/teams/${teamId}/reservations/${id}`)
            await updateRealtime(
              `/operation/${date}/tours/${tourId}/teams/${destinationTeamId}/reservations/${id}`,
              team.reservations[id],
            )
            const cleansedReservation = JSON.parse(
              JSON.stringify(team.reservations[id]))
            const changes = `Team change ${date}/${productName} reservation:${team.reservations[id]?.agencyCode}(${team.reservations[id]?.agency}) [TEAM${fromTeamNumb}(${team.id}) => TEAM${toTeamNumb}(${destinationTeamId})]`
            logAction('OPERATION', 'CHANGE RESERVATION TEAM', id.toString(),
              changes, {
                tourId,
                teamId: team.id,
                date,
                productName,
                cleansedReservation,
              }, { reservation: cleansedReservation })
          }
        }
      }
    })().catch((e) => {
      console.error(e)
      alert('팀 변경 중 문제가 발생했습니다.')
    })
  }

  const handleToggleBuilder = (
    id: string, property: 'check' | 'noShow' | 'star') => () => {
    const toggledReservation = reservations.find(
      (reservation) => reservation.id === id)
    if (!toggledReservation) return

    const toggledProperty = !toggledReservation[property]
    const update = { [property]: toggledProperty }
    updateFireStore('reservation', toggledReservation.id, update).
      catch(console.error)
    setReservationOverrides((pre) => ({
      ...pre,
      [toggledReservation.id]: {
        ...(pre[toggledReservation.id] ?? {}),
        ...update,
      },
    }))
    if (property === 'noShow') {
      logAction('OPERATION', 'NOSHOW RESERVATION', id,
        `Toggle Reservation(${id}) ${property.toUpperCase()} : ${toggledReservation[property]} => ${toggledProperty}`)
    }
    // updateRealtime(`/operation/${date}/tours/${toggledReservation.tourId}/teams/${toggledReservation.teamId}/reservations/${toggledReservation.id}`, update)
    //     .then(() => {
    //
    //     })
    //     .catch(console.error);

    // if (team) {
    //     setTeam((team) => {
    //         if (team === null) return null;
    //         return {...team};
    //     });
    // } else {
    //     setTour((tour) => {
    //         if (tour === null) return null;
    //         return {...tour};
    //     });
    // }
  }

  const handleSendMailBuilder = (
    id: string, row: ReservationRow) => (e: any) => {
    if (window.confirm(
      `${row.clientName}(${row.email})로 이메일(no-reply@ktourstory.com)을 발송하시겠습니까?`)) {
      setSendingReservations((sendings) => {
        return { ...sendings, [row.id]: true }
      })
      // const emailAddress = !window.confirm('ktourstory@gmail.com으로 보내시겠습니까?\n취소 시 no-reply@ktourstory.com으로 발송') ? 'no-reply@ktourstory.com' : 'ktourstory@gmail.com';
      const emailAddress = 'no-reply@ktourstory.com'
      const selectedGuides = findGuides(row)
      const isSeoul = tourId.includes('Seoul')
      const kup = isSeoul && selectedGuides.length > 0
      callFunction('sendReservationMailWith',
        { emailAddress, reservationId: row.id, kup }).then((resp: any) => {
        if (resp.success) {
          alert(`메일 발송 성공(${row.email})`)
          {
            setMailedReservations((r) => {
              return { ...r, [row.id]: true }
            })
          }
          updateRealtime(
            `/operation/${date}/tours/${tourId}/teams/${row.teamId}/reservations/${row.id}`,
            {
              mailedAt: new Date().toString(),
              kup,
            },
          ).catch(console.error)
        } else {
          throw new Error(resp.reason ?? 'unknown')
        }
      }).catch((e) => {
        alert(`메일 발송 실패(${row.email})\n${e}`)
        setSendingReservations((sendings) => {
          return { ...sendings, [row.id]: false }
        })
      })
    }
  }

  const handleSendShuttleMailBuilder = (
    id: string, row: ReservationRow) => (e: any) => {
    if (window.confirm(
      `${row.clientName}(${row.email})로 이메일(no-reply@ktourstory.com)을 셔틀 메일을 발송하시겠습니까?`)) {
      setSendingShuttleReservations((sendings) => {
        return { ...sendings, [row.id]: true }
      })
      const type = window.confirm('셔틀 업데이트 메일을 보내시겠습니까? (취소: 일반 메일)')
        ? 'update'
        : 'information'
      callFunction('sendShuttleMail',
        { reservationId: row.id, type }).then((resp: any) => {
        if (resp.success) {
          alert(`메일 발송 성공(${row.email})`)
          {
            setShuttleMailedReservations((r) => {
              return { ...r, [row.id]: true }
            })
          }
          updateRealtime(
            `/operation/${date}/tours/${tourId}/teams/${row.teamId}/reservations/${row.id}`,
            {
              shuttleMailedAt: new Date().toString(),
            },
          ).catch(console.error)
        } else {
          throw new Error(resp.reason ?? 'unknown')
        }
      }).catch((e) => {
        alert(`메일 발송 실패(${row.email})\n${e}`)
        setSendingShuttleReservations((sendings) => {
          return { ...sendings, [row.id]: false }
        })
      })
    }
  }

  const handleSendMails = () => {
    if (window.confirm(
      `${selectedReservations.length}개의 메일을 발송하시겠습니까? ${selectedReservations.length}초 가 소요됩니다.`)) {
      setSendingMails(true)
      setSendingReservations((sendings) => {
        const newSendings = Object.fromEntries(
          selectedReservations.map((r) => [r.id, true]))
        return { ...sendings, ...newSendings }
      })

      // const emailAddress = !window.confirm('ktourstory@gmail.com으로 보내시겠습니까?\n취소 시 no-reply@ktourstory.com으로 발송') ? 'no-reply@ktourstory.com' : 'ktourstory@gmail.com';
      const emailAddress = 'no-reply@ktourstory.com'
      const isSeoul = tourId.includes('Seoul')
      const validReservations = selectedReservations.filter(
        (r) => r.email?.includes('@'))

      setProgress(0);
      (async () => {
        const succeed: string[] = []
        const failed: string[] = []
        for (let idx = validReservations.length - 1; idx >= 0; idx--) {
          const reservation = validReservations[idx]

          setProgress(
            (validReservations.length - idx) / validReservations.length)

          try {
            const selectedGuides = findGuides(reservation)
            const kup = isSeoul && selectedGuides.length > 0
            const resp = await callFunction<any>('sendReservationMailWith', {
              emailAddress,
              reservationId: reservation.id,
              kup,
            })

            if (resp.success) {
              succeed.push(reservation.id)
              setMailedReservations((r) => {
                return { ...r, [reservation.id]: true }
              })
              updateRealtime(
                `/operation/${date}/tours/${tourId}/teams/${reservation.teamId}/reservations/${reservation.id}`,
                {
                  mailedAt: (new Date()).toString(),
                  kup,
                }).catch(console.error)

            } else {
              throw new Error(resp)
            }
          } catch (e) {
            failed.push(reservation.id)
            alert(`${reservation.clientName} 메일 발송에 실패했습니다.${e}`)
            setSendingReservations((sendings) => {
              return { ...sendings, [reservation.id]: false }
            })
          }
        }
        return { succeed, failed }
      })().then(({ succeed, failed }) => {
        alert(
          `전체:${selectedReservations.length} / 성공:${succeed.length} / 제외:${selectedReservations.length -
          validReservations.length} / 에러:${failed.length}`)
      }).catch((e) => {
        alert('메일 발송에 에러가 발생했습니다.')
        console.error(e)
      }).finally(() => {
        setSendingMails(false)
      })
    }
  }

  const handleSendShuttleMails = () => {
    if (window.confirm(
      `${selectedReservations.length}개의 셔틀 메일을 발송하시겠습니까? ${selectedReservations.length}초 가 소요됩니다.`)) {
      const type = window.confirm('셔틀 업데이트 메일을 보내시겠습니까? (취소: 일반 메일)')
        ? 'update'
        : 'information'
      setSendingMails(true)
      setSendingShuttleReservations((sendings) => {
        const newSendings = Object.fromEntries(
          selectedReservations.map((r) => [r.id, true]))
        return { ...sendings, ...newSendings }
      })

      // const emailAddress = !window.confirm('ktourstory@gmail.com으로 보내시겠습니까?\n취소 시 no-reply@ktourstory.com으로 발송') ? 'no-reply@ktourstory.com' : 'ktourstory@gmail.com';
      const validReservations = selectedReservations.filter(
        (r) => r.email?.includes('@'))

      setProgress(0);
      (async () => {
        const succeed: string[] = []
        const failed: string[] = []
        for (let idx = validReservations.length - 1; idx >= 0; idx--) {
          const reservation = validReservations[idx]

          setProgress(
            (validReservations.length - idx) / validReservations.length)

          try {
            const resp = await callFunction<any>('sendShuttleMail', {
              reservationId: reservation.id,
              type,
            })

            if (resp.success) {
              succeed.push(reservation.id)
              setShuttleMailedReservations((r) => {
                return { ...r, [reservation.id]: true }
              })
              updateRealtime(
                `/operation/${date}/tours/${tourId}/teams/${reservation.teamId}/reservations/${reservation.id}`,
                {
                  shuttleMailedAt: (new Date()).toString(),
                }).catch(console.error)

            } else {
              throw new Error(resp)
            }
          } catch (e) {
            failed.push(reservation.id)
            alert(`${reservation.clientName} 메일 발송에 실패했습니다.${e}`)
            setSendingShuttleReservations((sendings) => {
              return { ...sendings, [reservation.id]: false }
            })
          }
        }
        return { succeed, failed }
      })().then(({ succeed, failed }) => {
        alert(
          `전체:${selectedReservations.length} / 성공:${succeed.length} / 제외:${selectedReservations.length -
          validReservations.length} / 에러:${failed.length}`)
      }).catch((e) => {
        alert('메일 발송에 에러가 발생했습니다.')
        console.error(e)
      }).finally(() => {
        setSendingMails(false)
      })
    }
  }

  const handleDeleteOperationReservation = (
    id: string, row: ReservationRow) => {
    if (
      window.confirm(
        '해당 투어 내용을 삭제하시겠습니까? 투어 내용만 삭제되며 예약 정보가 취소되거나 삭제되지 않습니다.',
      )
    ) {
      removeRealtime(
        `/operation/${date}/tours/${tourId}/teams/${row.teamId}/reservations/${id}`).
        catch(
          console.error,
        )
    }
  }

  const handleOpenChat = async (row: any) => {
    setIsOpenChatLoading(true)
    const { id, clientName, product, productId, teamId, tourId, date } = row
    const category = 'PERSONAL'
    const title = ``
    const client = {
      id,
      name: clientName,
      nameEn: 'Client',
      type: 'client',
    } as any
    const me = {
      id: operator!.id,
      name: operator!.name,
      nameEn: operator!.nameEn,
      type: 'operator',
      photoURL: JSON.parse(operator!.raw).photoURL,
    }

    const participants = [client, me]

    if (client.device) {
      client['device'] = client.device
      callFunction('registerReservationDevice',
        { reservationId: id, device: client.device }).then(() => {
      }).catch(console.error)
    }

    const cId = participants.map((participant) => participant.id).
      sort((a, b) => (a > b ? 1 : 0)).
      join('')

    const chatMeta = (await callFunction('recallChat', {
      category: 'PERSONAL',
      participants,
      cId,
      title: `${date}:${product}:${clientName}:1대1문의`,
      tour: {
        date,
        tourId,
        teamId,
        productId,
      },
    })) as any
    setIsOpenChatLoading(false)

    window.open(`/chat/rooms/${chatMeta.id}`, '_blank', 'width=375,height=700')
  }

  const handleSetMultipleReservationMemos = () => {
    const memo = window.prompt('메모를 입력해주세요')
    if (memo && window.confirm(`선택하신 예약들에 메모를 "${memo}"를 쓰시겠습니까?`)) {
      const overwrite = window.confirm('덮어쓰시겠습니까? (취소 - 이어쓰기)')
      Promise.all(selectedReservations.map(async (r) => {
        const newMemo = overwrite ? memo : memo + '\n' + r.memo
        return updateFireStore('reservation', r.id, { memo: newMemo }).
          then(() => {
            setReservationOverrides((pre) => ({
              ...pre,
              [r.id]: {
                ...(pre[r.id] ?? {}),
                ...update,
              },
            }))
          })
      })).then(() => {
        logAction('RESERVATION', 'UPDATE RESERVATION',
          `reservations:${selectedReservations.map((r) => r.id).join(', ')}`,
          `Update ${selectedReservations.length} reservation memos, (${date}, ${tourId})`,
          {
            memo,
            reservationAgencyCodes: selectedReservations.map(r => r.agencyCode),
            reservationIds: selectedReservations.map(r => r.id),
          })
      }).catch((e) => {
        console.error(e)
        window.alert('반영 중 에러가 발생했습니다.')
      })
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'contacts',
      type: 'actions',
      maxWidth: !product?.category?.toLowerCase().includes('shuttle') ? 20 : 180,
      getActions: (params) => !product?.category?.toLowerCase().
        includes('shuttle') ? [
          <GridActionsCellItem
            sx={{ opacity: !params.row.email?.includes('@') ? 0 : 1 }}
            icon={
              params.row.kup ?
                params.row.isRead ? <LocalCafeIcon/> : <LocalCafeOutlinedIcon/>
                : params.row.isRead ? <CheckIcon/> : <MailIcon/>
            }
            disabled={params.row.sending || params.row.sent ||
              params.row.isRead || !params.row.email?.includes('@')}
            color={params.row.sending ? 'primary' : 'default'}
            onClick={handleSendMailBuilder(params.row.id, params.row)}
            label="Send Mail"
          />,

          // 챗 부르기
          // <GridActionsCellItem
          //     icon={
          //         isOpenChatLoading && params.id === params.row.agencyCode ? (
          //             <CircularProgress size={24}/>
          //         ) : (
          //             <ChatIcon color={"inherit"} fontSize={"large"}/>
          //         )
          //     }
          //     onClick={() => handleOpenChat(params.row)}
          //     label={"chat"}
          // />,
        ]
        :
        [
          <GridActionsCellItem
            sx={{ opacity: !params.row.email?.includes('@') ? 0 : 1 }}
            icon={
              params.row.kup ?
                params.row.isRead ? <LocalCafeIcon/> : <LocalCafeOutlinedIcon/>
                : params.row.isRead ? <CheckIcon/> : <MailIcon/>
            }
            disabled={params.row.sending || params.row.sent ||
              params.row.isRead || !params.row.email?.includes('@')}
            color={params.row.sending ? 'primary' : 'default'}
            onClick={handleSendMailBuilder(params.row.id, params.row)}
            label="Send Mail"
          />,
          <GridActionsCellItem
            sx={{ opacity: !params.row.email?.includes('@') ? 0 : 1 }}
            icon={<AirportShuttleIcon/>}
            disabled={params.row.sending || params.row.shuttleSent || !params.row.email?.includes('@')}
            color={params.row.sending ? 'primary' : 'default'}
            onClick={handleSendShuttleMailBuilder(params.row.id, params.row)}
            label="Send Mail"
          />,
        ],
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Star | No Show | Check',
      minWidth: 180,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            params.row.star ? (
              <StarIcon color={'primary'} fontSize={'large'}/>
            ) : (
              <StarOutlinedIcon fontSize={'large'}/>
            )
          }
          onClick={handleToggleBuilder(params.id + '', 'star')}
          label={'star'}
        />,
        <GridActionsCellItem
          icon={
            params.row.noShow ? (
              <NoShowIcon color={'primary'} fontSize={'large'}/>
            ) : (
              <NoShowOutlinedIcon fontSize={'large'}/>
            )
          }
          onClick={handleToggleBuilder(params.id + '', 'noShow')}
          label={'noShow'}
        />,
        <GridActionsCellItem
          icon={
            params.row.check ? (
              <CheckCircleIcon color={'primary'} fontSize={'large'}/>
            ) : (
              <CheckCircleOutlinedIcon fontSize={'large'}/>
            )
          }
          onClick={handleToggleBuilder(params.id + '', 'check')}
          label={'check'}
        />,
      ],
    },
    {
      field: 'teamIdx',
      headerName: 'TEAM',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'memo',
      headerName: 'MEMO',
      minWidth: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'date',
      headerName: 'DATE',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'product',
      headerName: 'PRODUCT',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'pickupPlace',
      headerName: 'PICKUP',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'people',
      headerName: 'PEOPLE',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'option',
      headerName: 'OPTION',
      minWidth: 700,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }

        const options = params.value?.split(',').
          map((s: string) => s.trim()).
          map((s: string) => {
            return (
              <span style={{ marginLeft: '2px', marginRight: '2px' }}>
              {
                s.includes('Basic Group Lesson')
                  ? <b>{s}</b>
                  : s
              }
            </span>
            )
          })

        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {options}
          </Box>
        )
      },
    },
    {
      field: 'stroller',
      headerName: 'STROLLER',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'clientName',
      headerName: 'NAME',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const count =
          reservationClientNameMap.get(params.value?.toLowerCase()) ??
          reservationClientNameMap.get(params.value)
        if (params.row.isMatched) {
          return (
            <Box fontWeight={params.row.isMatched ? 'bold' : 'inherit'}
                 color={params.row.isMatched ? 'error.main' : 'inherit'}
                 textAlign={'left'}
                 textOverflow={'ellipsis'}
                 overflow={'hidden'}>
              {count !== 1 ? `[${count}] ` : null}
              {params.value}
            </Box>
          )
        }
        if (count !== 1) {
          return (
            <Box
              component={'span'}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              })}
            >
              [{count}] {params.value}
            </Box>
          )
        }
        return params.value
      },
    },
    {
      field: 'nationality',
      headerName: 'NATIONALITY',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'language',
      headerName: 'LANGUAGE',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box
              fontWeight={'bold'}
              color={'error.main'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'agency',
      headerName: 'AGENCY',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box fontWeight={'bold'} color={'error.main'} textAlign={'left'}
                 textOverflow={'ellipsis'}
                 overflow={'hidden'}>
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'agencyCode',
      headerName: 'AGENCY CODE',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isMatched) {
          return (
            <Box fontWeight={'bold'} color={'error.main'} textAlign={'left'}
                 textOverflow={'ellipsis'}
                 overflow={'hidden'}>
              {params.value}
            </Box>
          )
        }
        return (
          <Box textAlign={'left'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {params.value}
          </Box>
        )
      },
    },

    {
      field: 'delete',
      type: 'actions',
      maxWidth: 50,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon/>}
          color={params.row.sending ? 'primary' : 'default'}
          onClick={() => handleDeleteOperationReservation(params.row.id,
            params.row)}
          label="Send Mail"
        />,
      ],
    },
  ]

  return (
    <>
      <Modal open={!!tourId} onClose={onClose}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative' as 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          })}
        >
          <Box
            sx={{
              position: 'fixed',
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 125%)',
              zIndex: 999,
              backgroundColor: 'white',
              borderRadius: 5,
              boxShadow: 2,
              py: 1,
              px: 2,
              gap: 3,
            }}
          >
            <ButtonBase
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
              onClick={() => {
                setRowSelectionModel([])
              }}
            >
              <Box
                color={'primary.main'}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Icon>
                  <ReservationIcon/>
                </Icon>
                <Typography variant={'h6'} color={'inherit'}>
                  {selectedReservations.reduce((result, a) => result + 1, 0).
                    toLocaleString()}
                </Typography>
              </Box>

              <Box
                color={'accentPrimary.main'}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Icon>
                  <PeopleIcon/>
                </Icon>
                <Typography variant={'h6'} color={'inherit'}>
                  {selectedReservations.reduce(
                    (result, a) => result + a.adult + a.infant + a.kid, 0).
                    toLocaleString()}
                </Typography>
              </Box>
            </ButtonBase>
            <IconButton
              disabled={selectedReservations.length === 0 || sendingMails}
              onClick={handleSendMails}
            >
              <MailIcon/>
            </IconButton>
            {
              product?.category?.toLowerCase().includes('shuttle') &&
              <IconButton
                disabled={selectedReservations.length === 0 || sendingMails}
                onClick={handleSendShuttleMails}
              >
                <AirportShuttleIcon/>
              </IconButton>
            }
            <IconButton
              disabled={selectedReservations.length === 0 || sendingMails}
              onClick={handleSetMultipleReservationMemos}
            >
              <MemoIcon/>
            </IconButton>
            <Stack gap={2} p={1} flexDirection={'row'}
                   onClick={(e) => e.stopPropagation()}>
              {teams.map(({ id, guides }, idx) => (
                <Chip
                  component={ButtonBase}
                  disabled={!rowSelectionModel?.length}
                  variant={'outlined'}
                  color={'primary'}
                  label={`Team ${idx + 1}`}
                  onClick={handleClickAssignTeamBuilder(id)}
                />
              ))}
            </Stack>
            <Box>
              <IconButton onClick={onClose}>
                <CloseIcon/>
              </IconButton>
            </Box>
          </Box>

          <Paper
            onClick={(e) => e.stopPropagation()}
            sx={(theme) => ({
              width: '92%',
              maxHeight: '84vh',
              overflowY: 'auto',
              padding: '32px 24px',
            })}
          >
            <Box mb={2}>
              <Typography gutterBottom variant={'h5'}>
                {date}
              </Typography>
              <Typography variant={'h4'}>
                {products?.[tour?.productId ??
                '']?.name.toUpperCase()}({products?.[tour?.productId ??
              '']?.area.toUpperCase()})
              </Typography>
            </Box>
            <Divider/>
            <Box my={4}>
              <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
                <ButtonBase
                  sx={(theme) => ({
                    display: 'inline-flex',
                    minWidth: '180px',
                    maxWidth: '240px',
                    borderRadius: 4,
                    border: `solid 1px ${
                      !team ? theme.palette.primary.main : theme.palette.divider
                    }`,
                  })}
                  onClick={handleClickAllTeam}
                >
                  <Typography variant={'h5'}>ALL</Typography>
                </ButtonBase>
                {teams.map((_team, idx) => (
                  <Box key={_team.id} width={'15%'} maxWidth={'240px'}>
                    <TeamButton
                      highlight={_team.id === team?.id}
                      date={date}
                      team={_team}
                      tour={tour!}
                      idx={idx}
                      onClick={handleSelectTeam}
                      onDelete={handleDeleteTeam}
                    />
                  </Box>
                ))}
                <ButtonBase
                  sx={(theme) => ({
                    display: 'inline-flex',
                    minWidth: '180px',
                    maxWidth: '240px',
                    borderRadius: 4,
                    border: `solid 1px ${theme.palette.divider}`,
                  })}
                  onClick={handleClickAddTeam}
                >
                  <PlusOneIcon fontSize={'large'}/>
                </ButtonBase>
              </Stack>
            </Box>
            <Divider/>
            {team ? (
              <Box
                sx={(theme) => ({
                  borderRadius: 3,
                  backgroundColor: theme.palette.background.default,
                  p: 4,
                  my: 4,
                })}
              >
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Typography>Hold Time</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DateTimePicker
                      label="holdTime"
                      sx={{
                        width: '100%',
                      }}
                      value={team?.holdTime ? dayjs(team.holdTime) : null}
                      onChange={handleChangeHoldTime}
                      format={'YYYY-MM-DD HH:mm'}
                    />
                    <Button
                      variant={'outlined'}
                      onClick={handleHoldTimeNow}
                      color={'secondary'}
                      sx={(theme) => ({
                        height: '100%',
                        ml: 2,
                      })}
                    >
                      Now
                    </Button>
                  </Grid>

                  <Grid item xs={1}>
                    <Typography>Dispatch</Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <TextField
                      select
                      fullWidth
                      label={'첫 픽업지'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{ native: true }}
                      value={teamPickup?.place ?? ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handeChangeTourTeamRowProps('pickup', e.target.value)
                      }}
                    >
                      {
                        Object.entries(area.pickupNameMap).
                          map(([value, label]) => (
                            <option key={value} value={value}>{label}</option>))
                      }
                    </TextField>
                  </Grid>

                  <Grid item xs={1}>
                    <TextField
                      select
                      fullWidth
                      label={'인원수'}
                      type={'number'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{ native: true }}
                      value={Math.min(teamPeople, 43)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handeChangeTourTeamRowProps('people',
                          Number.parseInt(e.target.value))
                      }}
                    >
                      {(new Array(43).fill(1).map((_, idx) => idx + 1)).map(
                        p => (
                          <option key={p} value={p}>{p}명</option>))
                      }
                    </TextField>
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      select
                      label={'차종'}
                      name="vehicle"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{ native: true }}
                      value={teamVehicle}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handeChangeTourTeamRowProps('vehicleModel',
                          e.target.value)
                      }}
                    >
                      {area.vehicles.map(
                        p => (<option key={p} value={p}>{p}</option>))}
                    </TextField>
                  </Grid>

                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label={'차량번호'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={teamVehicleNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handeChangeTourTeamRowProps('vehicleNumber',
                          e.target.value)
                      }}
                    >

                    </TextField>
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label={'운전기사'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={teamDriverName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handeChangeTourTeamRowProps('driverName',
                          e.target.value)
                      }}/>
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label={'운전기사 연락처'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={teamDriverContact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handeChangeTourTeamRowProps('driverContact',
                          e.target.value)
                      }}/>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label={'배차 메모'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={teamDispatchMemo}
                      onChange={(e) => {
                        handeChangeTourTeamRowProps('memo', e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      disabled
                      label={'합승 여부'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={teamMergeTeam ? '합승 O' : '합승 X'}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {
                      hasDefinedDispatch && <Box sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>

                        <IconButton onClick={() => clearTourTeamProperties()}>
                          <Icon>
                            restore
                          </Icon>
                        </IconButton>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>Guide</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      id={team.id + 'guide'}
                      options={guideOptions}
                      value={teamGuideSelected}
                      onChange={handleChangeGuides}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || option === value
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.name}
                            color={option.color as 'accentPrimary' | 'accentSecondary' | 'warning' | 'success' | 'info' | 'default' | 'error' | 'primary' | 'secondary' | undefined}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        const { title, color } = option
                        return (
                          <Typography component={'span'}
                                      color={color + '.main'} {...props}>
                            {title}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="가이드" placeholder="가이드"/>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      id={team.id + 'guide'}
                      options={guideOptions}
                      value={teamApprenticeshipSelected}
                      onChange={handleChangeApprenticeship}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || option === value
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.name}
                            color={option.color as 'accentPrimary' | 'accentSecondary' | 'warning' | 'success' | 'info' | 'default' | 'error' | 'primary' | 'secondary' | undefined}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        const { title, color } = option
                        return (
                          <Typography component={'span'}
                                      color={color + '.main'} {...props}>
                            {title}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="수습" placeholder="수습"/>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      id={team.id + 'guide'}
                      options={guideOptions}
                      value={teamExplorationSelected}
                      onChange={handleChangeExploration}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || option === value
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.name}
                            color={option.color as 'accentPrimary' | 'accentSecondary' | 'warning' | 'success' | 'info' | 'default' | 'error' | 'primary' | 'secondary' | undefined}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        const { title, color } = option
                        return (
                          <Typography component={'span'}
                                      color={color + '.main'} {...props}>
                            {title}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="답사" placeholder="답사"/>
                      )}
                    />
                  </Grid>
                  {/*
                  교육 추가 필요
                  */}
                  <Grid item xs={2}>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      id={team.id + 'guide'}
                      options={guideOptions}
                      value={teamDriverSelected}
                      onChange={handleChangeDriver}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || option === value
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.name}
                            color={option.color as 'accentPrimary' | 'accentSecondary' | 'warning' | 'success' | 'info' | 'default' | 'error' | 'primary' | 'secondary' | undefined}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        const { title, color } = option
                        return (
                          <Typography component={'span'}
                                      color={color + '.main'} {...props}>
                            {title}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="운전" placeholder="운전"/>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={1}>
                    <Typography>Memo</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      value={typeof team.memo === 'string'
                        ? team.memo
                        : team.memo?.office ?? ''}
                      onChange={handleChangeTeamMemoBuilder('office')}
                    />
                  </Grid>
                  {team.memo && typeof team.memo !== 'string' &&
                  Object.keys(team.memo).
                    filter((key) => key !== 'office').length > 0
                    ? Object.entries(team.memo).
                      filter(([key, value]) => key !== 'office' && !!value).
                      map(([key]) => {
                        return (
                          <>
                            <Grid item xs={1}>
                              <Typography>
                                {team?.guides?.find(
                                  (g) => g.id === key)?.name ?? key}
                              </Typography>
                            </Grid>
                            <Grid item xs={11}>
                              <TextField
                                fullWidth
                                multiline
                                rows={3}
                                value={
                                  (team.memo as {
                                    [key: string]: string
                                  })[key] ??
                                  ''
                                }
                                onChange={handleChangeTeamMemoBuilder(key)}
                              />
                            </Grid>
                          </>
                        )
                      })
                    : null}
                </Grid>
              </Box>
            ) : null}
            <Box mt={4}>
              <DataGridPro
                sx={{ color: '#2a2f37' }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'clientName', 'sort': 'asc' }],
                  },
                }}
                hideFooter
                checkboxSelection
                disableRowSelectionOnClick
                rows={reservations}
                columns={columns}
                onRowClick={handleReservationRowClick}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={handleSelectRows}
              />
            </Box>
          </Paper>
        </Box>
      </Modal>
      {reservationId ? (
        <ReservationModal
          onCopy={(reservation) => {
            setCopyReservation(reservation)
          }}
          reservationId={reservationId}
          onClose={(update?) => {
            setReservationId(null)
            if (update) {
            }
          }}
        />
      ) : null}
      {copyReservation ? (
        <CreateReservationModal
          defaultReservation={copyReservation}
          onClose={(update) => {
            setCopyReservation(null)
          }}
        />
      ) : null}
      {
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!snackbarMessage}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarMessage(null)
          }}
          message={snackbarMessage}
        />
      }
      sendingMails
      ? (
      <Modal open={sendingMails}>
        <Box
          color={'white'}
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color={'inherit'}/>
          <Typography color={'inherit'}>
            {Math.round(progress * 100)}%
          </Typography>
        </Box>
      </Modal>
      )
      :null
    </>
  )
}

type Pickup = {
  place: string,
  time: string
}

function reducePickups (
  reservations: ReservationBase[], product: Product): Pickup[] {
  const pickupSet = reservations.reduce((result, reservation) => {
    result.add(reservation.pickupPlace)
    return result
  }, new Set<string>)

  const sortedPickups = Object.entries(product.chat?.pickup ?? {}).
    filter(([_, p]) => p.use).
    sort(([_, a], [__, b]) => {
      return ((a.order ?? 99) - (b.order ?? 99))
    }).
    map(([_, pickup]) => pickup.place).
    filter(pickup => pickupSet.has(pickup)).
    map((pickup) => {
      const time = (product.winter
        ? product.chat?.pickup?.[pickup]?.winterTime
        : product.chat?.pickup?.[pickup]?.time) ?? 'Unknown Time'
      return { place: pickup, time }
    })
  return sortedPickups
}

function checkVehicleType (vehicle: string): [string, string, string] {
  if (vehicle === '도보') return ['도보', '도보', '']
  if (vehicle === '스타리아') return ['스타리아', '스타리아', '']
  if (vehicle === '쏠라티') return ['쏠라티', '쏠라티', '']
  if (vehicle === '카운티') return ['카운티', '카운티', '']
  if (vehicle === '45인승') return ['45인승', '버스', '']
  if (vehicle === 'Walking') return ['Walking', 'Walking', '']
  if (vehicle === 'Hiace') return ['Hiace', 'Hiace', '']
  if (vehicle === '18-seater Bus') return [
    '18-seater Bus',
    '18-seater Bus',
    '']
  if (vehicle === '27-seater Bus') return [
    '27-seater Bus',
    '27-seater Bus',
    '']
  if (vehicle === '28-seater Bus') return [
    '28-seater Bus',
    '28-seater Bus',
    '']
  if (vehicle === '32-seater Bus') return [
    '32-seater Bus',
    '32-seater Bus',
    '']
  if (vehicle === '45-seater Bus') return [
    '45-seater Bus',
    '45-seater Bus',
    '']
  if (vehicle === '49-seater Bus') return [
    '49-seater Bus',
    '49-seater Bus',
    '']
  return ['', '', '']
}

function assignVehicleSeoul (
  people: number, product?: string): [string, string, string] {
  if (product === '광장시장') {
    return checkVehicleType('도보')
  }
  if (people <= 7) return checkVehicleType('스타리아')
  if (people <= 15) return checkVehicleType('카운티')
  return checkVehicleType('45인승')
}

function assignVehicleBusan (people: number): [string, string, string] {
  if (people <= 7) return checkVehicleType('스타리아')
  if (people <= 15) return checkVehicleType('카운티')
  return checkVehicleType('45인승')
}

function assignVehicleTokyo (people: number): [string, string, string] {
  if (people <= 9) return checkVehicleType('Hiace')
  if (people <= 25) return checkVehicleType('27-seater Bus')
  if (people <= 26) return checkVehicleType('28-seater Bus')
  // if (people <= 30) return ('32-seater Bus');
  return checkVehicleType('49-seater Bus')
}


function assignVehicleOsaka (people: number): [string, string, string] {
  if (people <= 9) return checkVehicleType('Hiace')
  if (people <= 25) return checkVehicleType('27-seater Bus')
  if (people <= 26) return checkVehicleType('28-seater Bus')
  // if (people <= 30) return ('32-seater Bus');
  return checkVehicleType('49-seater Bus')
}

type Area = {
  id: string,
  name: string,
  vehicles: string[],
  assignVehicle: (people: number) => [string, string, string],
  pickupNameMap: { [pickupName: string]: string }
}
const AREAS: { [id: string]: Area } = {
  'seoul': {
    id: 'seoul',
    name: 'Seoul',
    vehicles: ['스타리아', '카운티', '45인승', '쏠라티', '도보', '동승'],
    assignVehicle: assignVehicleSeoul,
    pickupNameMap: {
      'Hongdae': '홍',
      'Myungdong': '명',
      'Dongdaemoon': '동',
      'Gwanghwamun': '광화문',
      'Jongno': '종로',
      'Yeouido': '여의도',
    },
  },
  'busan': {
    id: 'busan',
    name: 'Busan',
    vehicles: ['스타리아', '카운티', '45인승', '쏠라티', '도보', '동승'],
    assignVehicle: assignVehicleBusan,
    pickupNameMap: {
      'Busan Station': '부산역',
      'Seomyun': '서면',
      'Haeundaae': '해운대',
    },
  },
  'tokyo': {
    id: 'tokyo',
    name: 'Tokyo',
    vehicles: [
      'Hiace',
      '27-seater Bus',
      '28-seater Bus',
      '49-seater Bus',
      'Shared'],
    assignVehicle: assignVehicleTokyo,
    pickupNameMap: {
      'Tokyo Station': 'Tokyo',
      'Shinjuku-nishiguchi': 'Shinjuku',
    },
  },
  'osaka': {
    id: 'osaka',
    name: 'Osaka',
    vehicles: [
      'Hiace',
      '27-seater Bus',
      '28-seater Bus',
      '49-seater Bus',
      'Shared'],
    assignVehicle: assignVehicleOsaka,
    pickupNameMap: {
      'Tsurutontan Soemoncho ': 'Tsurutontan',
    },
  },
}
