import React, { lazy, Suspense, useMemo, useState } from "react"

import { useQuery } from "@tanstack/react-query"
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js"
import zoomPlugin from "chartjs-plugin-zoom"

import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CircularProgress from "@mui/material/CircularProgress"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

import { readRealtime } from "../../hooks/firebase"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend,
    zoomPlugin
)

const LazyCompareTab = lazy(() => import("./CompareTab"))
const LazyAggregationTab = lazy(() => import("./AggregationTab"))

export default function () {
    const [tabValue, setTabValue] = useState<string>("compare")

    const handleChangeTab = (_: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const { data: products, isLoading: isLoadingProducts } = useQuery({
        queryKey: ["products"],
        queryFn: () => readRealtime("/product"),
        staleTime: 10 * 60 * 1000, // refetch after 10 minutes
    })

    const { data: agencies, isLoading: isLoadingAgencies } = useQuery({
        queryKey: ["agencies"],
        queryFn: () => readRealtime("/agency"),
        staleTime: 10 * 60 * 1000, // refetch after 10 minutes
    })

    const { data: nationalities, isLoading: isLoadingNationalities } = useQuery({
        queryKey: ["nationalities"],
        queryFn: () => readRealtime("/nationality"),
        staleTime: 10 * 60 * 1000, // refetch after 10 minutes
    })

    const productOptions = useMemo(() => {
        return Object.values(products ?? {})
            .filter((p) => p.id && p.name)
            .map((product) => ({
                id: product.id,
                area: product.area,
                category: product.category,
                name: product.name,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
    }, [products])

    const agencyOptions = useMemo(() => {
        return Object.values(agencies ?? [])
            .filter((a) => a.code && a.name)
            .map((agency) => ({
                id: agency.code,
                name: agency.name,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
    }, [agencies])

    const nationalityOptions = useMemo(() => {
        return [{ id: "", name: "NONE" }].concat(
            Object.values(nationalities ?? [])
                .map((nationality) => ({
                    id: nationality.place,
                    name: nationality.place,
                }))
                .sort((a, b) => (a.name > b.name ? 1 : -1))
        )
    }, [nationalities])

    if (isLoadingProducts && isLoadingAgencies && isLoadingNationalities)
        return (
            <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress sx={{ margin: "auto" }} />
            </Box>
        )

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: 8,
            }}
        >
            <Card>
                <CardContent
                    sx={(theme) => ({
                        display: "flex",
                        justifyContent: "space-between",
                        color: theme.palette.text.primary,
                        padding: "8px !important",
                        gap: 2,
                    })}
                >
                    <Tabs value={tabValue} onChange={handleChangeTab}>
                        <Tab label="Compare" value={"compare"} />
                        <Tab label="Aggregation" value={"aggregation"} />
                    </Tabs>
                </CardContent>
            </Card>

            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                {tabValue === "compare" && (
                    <Suspense fallback={null}>
                        <LazyCompareTab
                            productOptions={productOptions}
                            agencyOptions={agencyOptions}
                            nationalityOptions={nationalityOptions}
                        />
                    </Suspense>
                )}
                {tabValue === "aggregation" && (
                    <Suspense fallback={null}>
                        <LazyAggregationTab productOptions={productOptions} agencyOptions={agencyOptions} />
                    </Suspense>
                )}
            </Box>
        </Box>
    )
}
