import React, {createContext, useContext, ReactNode, useEffect, useState, useLayoutEffect} from "react"
import {User} from "@firebase/auth"
import {
    getCurrentUser,
    listenRealtime,
    onAuthStateChange,
    readRealtime,
    readWithKeyStartAt,
    readWithKeyStartEndAt, removeUserToken,
    signInUser,
    signOutUser,
} from "../firebase"
import {escapeEmail} from "../../utils/textUtils"
import {IUser} from "../../types/chat"

interface UserAuth {
    email: string
    level: number
    name: string
    id: string,
    area: string
}

interface AuthContextValue {
    user: User | null
    operator: IUser | null
    auth: UserAuth | null
    loading: boolean
    logout: () => Promise<void>
    login: () => Promise<User | null>
}

export const AuthContext = createContext<AuthContextValue>({
    user: null,
    operator: null,
    auth: null,
    loading: true,
    logout: signOutUser,
    login: signInUser,
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null)
    const [operator, setOperator] = useState<IUser | null>(null)
    const [userAuth, setUserAuth] = useState<UserAuth | null>(null)
    const [loading, setLoading] = useState(true)

    useLayoutEffect(() => {
        const unsubscribeAuthChange = onAuthStateChange((_user) => {
            setLoading(false)
            setUser(_user)
            if (_user) {
                _user.getIdTokenResult(true).then((tokens) => {
                    if (tokens.claims.level > 1.25) {
                        window?.alert("권한이 없습니다. 다른 계정으로 재로그인이 필요합니다.")
                        signOutUser().catch((error) => {
                            console.error(error)
                            alert("로그아웃할 수 없습니다.")
                        })
                        setUser(null)
                        setUserAuth(null)
                    }
                })
            } else {
                removeUserToken().catch(console.error);
                setUser(null)
                setUserAuth(null)
            }
        })

        return () => {
            unsubscribeAuthChange()
        }
    }, [])

    useEffect(() => {
        if (!user){
            return;
        }

        async function getUserInfo(user: User) {
            const data = await readWithKeyStartEndAt("user", user.uid, user.uid)
            setOperator(data[user.uid] as IUser)
        }

        getUserInfo(user)
    }, [user])

    useEffect(() => {
        if (loading) return
        if (!user && window?.confirm("로그인하시겠습니까?")) {
            signInUser().catch((error) => {
                console.error(error)
                alert(error.message)
            })
        }

        if (user?.uid) {
            const unsubscribeUserDataChange = listenRealtime<UserAuth>(`/user/${user.uid}`, (userAuthChange) => {
                if (userAuthChange) {
                    const s1 = JSON.stringify(userAuth ?? {})
                    const s2 = JSON.stringify(userAuthChange)
                    if (!userAuth) {
                        setUserAuth(userAuthChange)
                    } else if (userAuth.level !== userAuthChange.level) {
                        signOutUser()
                            .then(() => {
                                alert("유저 정보 변경으로 로그아웃되었습니다.")
                            })
                            .catch((error) => {
                                console.error(error)
                                alert("로그아웃할 수 없습니다.")
                            })
                        setUserAuth(userAuthChange)
                    }
                }
            })
            return () => {
                unsubscribeUserDataChange()
            }
        }
    }, [loading, user, userAuth])

    const value: AuthContextValue = {
        user,
        operator,
        auth: userAuth,
        loading,
        logout: signOutUser,
        login: signInUser,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
