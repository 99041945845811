import React from "react"
import { createTheme } from "@mui/material/styles"

declare module "@mui/material" {
    interface ChipPropsColorOverrides {
        accentPrimary: true
        accentSecondary: true
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        accentPrimary: Palette["primary"]
        accentSecondary: Palette["primary"]
    }

    interface PaletteOptions {
        accentPrimary: PaletteOptions["primary"]
        accentSecondary: PaletteOptions["primary"]
    }
}
export default createTheme({
    typography: {
        fontFamily: [
            '"Pretendard Variable"',
            "Pretendard",
            "-apple-system",
            "BlinkMacSystemFont",
            "system-ui",
            "Roboto",
            '"Helvetica Neue"',
            '"Segoe UI"',
            '"Apple SD Gothic Neo"',
            '"Noto Sans KR"',
            '"Malgun Gothic"',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            "sans-serif",
        ].join(","),

        h4: {
            fontWeight: "bold",
            fontSize: "2rem",
        },
        h5: {
            fontWeight: "bold",
            fontSize: "1.6rem",
        },
        h6: {
            fontSize: "1.25rem",
        },
        subtitle1: {
            fontSize: "1.125rem",
        },
        subtitle2: {
            fontSize: "1rem",
        },
    },
    palette: {
        primary: {
            main: "#1577BA",
        },
        secondary: {
            main: "#15BAAA",
        },
        error: {
            main: "#FF4C41",
        },
        warning: {
            main: "#FF8744",
        },
        info: {
            main: "#FFCB44",
        },
        success: {
            main: "#55B877",
        },
        accentPrimary: {
            main: "#ff0060",
            contrastText: "#fff",
        },
        accentSecondary: {
            main: "#b600f6",
            contrastText: "#fff",
        },

        background: {
            default: "#FAFAFA",
            paper: "#FFF",
        },
        text: {
            primary: "#4F4F4F",
            secondary: "#2A2F37",
            disabled: "#828282",
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    "@media (min-width:600px)": {
                        padding: 0,
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                }),
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "@media (min-width:600px)": {
                        padding: "16px 60px",
                    },
                }),
            },
        },
    },
})
