import React, {useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import {DatePicker} from "@mui/x-date-pickers";

import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/CopyAll";
import CloseIcon from '@mui/icons-material/Close';
import RightIcon from '@mui/icons-material/ArrowRight';
import MailIcon from '@mui/icons-material/Mail';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay'

import {Reservation} from "../models/Reservation";
import {Product} from '../models/Product';
import {Pickup} from "../models/Pickup";
import {Agency} from "../models/Agency";
import {Nationality} from "../models/Nationality";

import MailPreview from "./MailPreview";
import {useDoc} from "../hooks/firestore";
import {useBase} from "../hooks/base";
import {logAction, removeRealtime} from "../hooks/firebase";
import dayjs from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


export default function (props: {
    reservationId: string,
    onCopy: (reservation: Reservation) => void,
    onClose: (update?: boolean) => void,
    disabled?: boolean,

}) {
    const {reservationId, onClose, onCopy, disabled} = props;
    const {agencies, nationalities, products, pickupGroup} = useBase();

    const [openPreview, setOpenPreview] = useState(false);

    const {
        data: reservation,
        original: originalReservation,
        update: updateReservation,
        updates: updatesReservation,
        save: saveReservation,
        loading
    } = useDoc<Reservation>('reservation', reservationId);


    const product = products && reservation ? products[reservation.productId] : null;
    const pickups = pickupGroup && product ? pickupGroup[product?.area.toLowerCase()] : null;


    const agencyOptions = Object.entries(agencies ?? {}).map(([agencyId, agency]) => ({
        id: agency.code,
        name: agency.name
    }))
    const pickupOptions = Object.entries(pickups ?? {}).sort(([_, a], [__, b]) => (a.order ?? 99) - (b.order ?? 99)).map(([pickupId, pickup]) => ({
        id: pickupId,
        name: pickup.place
    }))
    const nationalityOptions = Object.entries(nationalities ?? {}).map(([nationId, nationality]) => ({
        id: nationality.place,
        name: `${nationality.place}`
    }));
    const productOptions = Object.values(products ?? {}).filter((p) => {
        const date = new Date(reservation?.date ?? '');
        const day = date.getDay() === 0 ? 6 : date.getDay() - 1;
        return p.status === 'ON' && p.availableDays[day];
    }).map((product) => ({id: product.id, name: product.name}));

    const options = (product?.option ?? []).filter((option) => {
        // return !(reservation?.option.map((o) => o.option).concat(['Ignore']).includes(option.option));
        return !['Ignore'].includes(option.option);
    }).map((option) => ({
        option: option.option,
        people: reservation?.option.find((o) => o.option === option.option)?.people ?? 0
    }));

    const otherOptions = (reservation?.option ?? []).filter(option => {
        const existOption = options.find(o => o.option === option.option);
        return !existOption;
    });


    const handleTogglePreview = () => {
        setOpenPreview((b) => !b);
    }


    const handleSave = () => {
        if (!reservation) return alert('예약정보가 없습니다.');
        if (!reservation.date) return alert('날짜 정보를 입력해주세요.');
        if (!reservation.product) return alert('상품 정보를 입력해주세요.');
        if (!reservation.pickupPlace) return alert('픽업 정보를 입력해주세요.');
        if (
            ((!reservation.adult && reservation.adult !== 0) || Number.isNaN(reservation.adult) || reservation.adult < 0)
            || ((!reservation.kid && reservation.kid !== 0) || Number.isNaN(reservation.kid) || reservation.kid < 0)
            || ((!reservation.infant && reservation.infant !== 0) || Number.isNaN(reservation.infant) || reservation.infant < 0)
            || (reservation.adult + reservation.kid + reservation.infant) <= 0
        ) return alert('구성원 정보를 입력해주세요.');

        const diff = Object.entries(originalReservation ?? {}).filter((entry: [string, any]) => JSON.stringify(entry[1]) !== JSON.stringify((reservation as any)?.[entry[0]])).map((entry) => `[${entry[0]}, ${JSON.stringify(entry[1])} => ${JSON.stringify((reservation as any)?.[entry[0]])}]`).join('\r\n')
        const value = {from: JSON.parse(JSON.stringify(originalReservation)), to: JSON.parse(JSON.stringify(reservation))};
        saveReservation()
            .then(() => {
                logAction('RESERVATION', 'UPDATE RESERVATION', reservationId,
                    `Update reservation: ${originalReservation?.id ?? reservationId}(${originalReservation?.date}, ${originalReservation?.agency}, ${originalReservation?.agencyCode})\r\n${diff}`,
                    value,
                    {reservation: JSON.parse(JSON.stringify(reservation))}
                    );
                onClose(true);
                if (reservation?.productId !== originalReservation?.productId || reservation?.date !== originalReservation?.date) {
                    if (originalReservation?.tourId && originalReservation?.teamId) {
                        removeRealtime(`operation/${originalReservation.date}/tours/${originalReservation.tourId}/teams/${originalReservation.teamId}/reservations/${originalReservation.id}`).catch(console.error);
                    }
                }
            }).catch(console.error);
    }
    const handleDelete = () => {
        if (!(window?.confirm("예약을 취소하시겠습니까?"))) return;
        saveReservation({canceledAt: (new Date()).toString()})
            .then(() => {
                logAction('RESERVATION', 'CANCEL RESERVATION', reservationId,
                    `Cancel reservation: ${originalReservation?.id ?? reservationId}(${originalReservation?.date}, ${originalReservation?.agency}, ${originalReservation?.agencyCode})`
                    , originalReservation ?? {id: reservationId},
                    {reservation: JSON.parse(JSON.stringify(reservation))}
                );
                onClose(true);
            })
            .catch(console.error)
    }
    const handleRecover = () => {
        saveReservation({canceledAt: null})
            .then(() => {
                logAction('RESERVATION', 'RECOVER RESERVATION', reservationId,
                    `Recover reservation: ${originalReservation?.id ?? reservationId}(${originalReservation?.date}, ${originalReservation?.agency}, ${originalReservation?.agencyCode})`
                    , originalReservation ?? {id: reservationId},
                    {reservation: JSON.parse(JSON.stringify(reservation))}
                );
                onClose(true);
            })
            .catch(console.error)
    }


    const handleCopy = () => {
        if (reservation) {
            onCopy({ ...reservation, product:product?.name ?? reservation.product });
            onClose(false);
        }
    }
    const handleClose = () => {
        onClose(false);
    }

    return (
        <Modal
            open={!!reservationId}
            onClose={handleClose}
        >
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative' as 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >

                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 125%)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 1,
                    px: 2
                }}>
                    {
                        disabled
                            ? (
                                <>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon/>
                                    </IconButton>
                                </>
                            )
                            : loading
                                ? <CircularProgress color={"primary"}/>
                                : <>
                                    {
                                        reservation?.canceledAt
                                            ?
                                            <IconButton disabled={disabled} onClick={handleRecover}>
                                                <ReplayIcon/>
                                            </IconButton>
                                            :
                                            <IconButton disabled={disabled} onClick={handleDelete}>
                                                <DeleteIcon/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled}
                                                onClick={handleTogglePreview}
                                    >
                                        {
                                            openPreview ?
                                                <RightIcon/>
                                                : <MailIcon/>
                                        }
                                    </IconButton>
                                    {

                                        reservation?.canceledAt
                                            ? null
                                            : <IconButton disabled={disabled} onClick={handleSave}>
                                                <EditIcon/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled} onClick={handleCopy}>
                                        <CopyIcon/>
                                    </IconButton>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon/>
                                    </IconButton>
                                </>
                    }

                </Box>


                <Paper
                    sx={(theme) => ({
                        width: '92%',
                        height: '84%',
                        overflowY: 'auto',
                        padding: '32px 24px',
                    })}

                >

                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={openPreview ? 7 : 12}
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    maxHeight: '75vh',
                                    overflowY: 'auto',
                                }}
                            >

                                <Box
                                    sx={(theme) => ({
                                        borderRadius: 3,
                                        backgroundColor: theme.palette.background.default,
                                        p: 4
                                    })}
                                >
                                    <Grid
                                        container
                                        spacing={4}
                                        component={'form'}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                            <Typography
                                                fontWeight={'bold'}>
                                                Tour
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                        >

                                            <DatePicker
                                                disabled={disabled || !!reservation?.canceledAt}
                                                disablePast
                                                format={'YYYY-MM-DD'}
                                                value={dayjs(reservation?.date)}
                                                onChange={(date) => {
                                                    if (date) {
                                                        updatesReservation({
                                                            date: dayjs(date).format('YYYY-MM-DD')
                                                        })
                                                    }
                                                }}
                                                slotProps={{
                                                    // inputAdornment: {
                                                    //     sx:{display:'none'}
                                                    // },
                                                    textField: {
                                                        fullWidth: true,
                                                        sx: {backgroundColor: 'white'},
                                                        label: 'Date',
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                        >
                                            <Autocomplete
                                                key={'pickup'}
                                                disabled={disabled || !!reservation?.canceledAt}
                                                options={pickupOptions}
                                                getOptionLabel={(option) => option.name}
                                                value={{
                                                    id: reservation?.pickupPlace ?? '',
                                                    name: reservation?.pickupPlace ?? ''
                                                }}
                                                onChange={(_, pickupOption) => {
                                                    if (pickupOption) {
                                                        updatesReservation({
                                                            pickupPlace: pickupOption.id,
                                                        });
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{backgroundColor: 'white'}}
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={'Pick up'}
                                                            InputLabelProps={{shrink: true}}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={10}
                                        >
                                            <Autocomplete
                                                disabled={disabled || !!reservation?.canceledAt}
                                                options={productOptions}
                                                getOptionLabel={(option) => option.name}
                                                value={{
                                                    id: product?.id ?? '',
                                                    name: product?.name ?? ''
                                                }}
                                                onChange={(_, productOption) => {
                                                    if (productOption) {
                                                        updatesReservation({
                                                            productId: productOption.id,
                                                            product: productOption.name,
                                                        });
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{backgroundColor: 'white'}}
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={'Product'}
                                                            InputLabelProps={{shrink: true}}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                        >
                                            <Divider/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                            <Typography
                                                fontWeight={'bold'}
                                            >
                                                Agency
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                        >

                                            <Autocomplete
                                                disabled={disabled || !!reservation?.canceledAt}
                                                options={agencyOptions}
                                                getOptionLabel={(option) => `${option.id}(${option.name})`}
                                                value={{
                                                    id: reservation?.agency ?? '',
                                                    name: reservation?.agency ?? ''
                                                }}
                                                onChange={(_, agencyOption) => {
                                                    if (agencyOption) {
                                                        updatesReservation({
                                                            agency: agencyOption.id,
                                                        });
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{backgroundColor: 'white'}}
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={'Agency'}
                                                            InputLabelProps={{shrink: true}}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Agency Code'}
                                                value={reservation?.agencyCode}
                                                onChange={(event) => {
                                                    updatesReservation({
                                                        agencyCode: (event.target.value ?? "").trim(),
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                        >
                                            <Divider/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                            <Typography
                                                fontWeight={'bold'}>
                                                Client
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={10}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Client Name'}
                                                value={reservation?.clientName}
                                                onChange={(event) => {
                                                    updatesReservation({
                                                        clientName: event.target.value,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Adult'}
                                                type={'number'}
                                                value={reservation?.adult}
                                                onChange={(event) => {
                                                    const number = Number.parseInt(event.target.value);
                                                    const adult = number;
                                                    updatesReservation({
                                                        adult: adult,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Kid'}
                                                type={'number'}
                                                value={reservation?.kid}
                                                onChange={(event) => {
                                                    const number = Number.parseInt(event.target.value);
                                                    const kid = number;
                                                    updatesReservation({
                                                        kid,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Infant'}
                                                type={'number'}
                                                value={reservation?.infant}
                                                onChange={(event) => {
                                                    const number = Number.parseInt(event.target.value);
                                                    const infant = number;
                                                    updatesReservation({
                                                        infant,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Client Email'}
                                                value={reservation?.email}
                                                onChange={(event) => {
                                                    updatesReservation({
                                                        email: event.target.value,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Client Tel'}
                                                value={reservation?.tel}
                                                onChange={(event) => {
                                                    updatesReservation({
                                                        tel: event.target.value,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                label={'Client Messenger'}
                                                value={reservation?.messenger}
                                                onChange={(event) => {
                                                    updatesReservation({
                                                        messenger: event.target.value,
                                                    });
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                        >

                                            <Autocomplete
                                                disabled={disabled || !!reservation?.canceledAt}
                                                options={nationalityOptions}
                                                getOptionLabel={(option) => `${option.name}`}
                                                value={{
                                                    id: reservation?.nationality ?? '',
                                                    name: reservation?.nationality ?? ''
                                                }}
                                                onChange={(_, nationalityOption) => {
                                                    if (nationalityOption) {
                                                        updatesReservation({
                                                            nationality: nationalityOption.id,
                                                        });
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{backgroundColor: 'white'}}
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={'Nationality'}
                                                            InputLabelProps={{shrink: true}}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                        >
                                            <Autocomplete
                                                multiple
                                                disabled={disabled}
                                                options={[
                                                    {id: 'English', name: 'English'},
                                                    {id: 'Chinese', name: 'Chinese'},
                                                    {id: 'Japanese', name: 'Japanese'},
                                                    {id: 'Korean', name: 'Korean'},
                                                ]}
                                                value={
                                                    ((reservation?.language ?? '').split(/- /g)[0]).split(',').map(s => s.trim()).filter(s => !!s)
                                                        .map((language) => ({
                                                            id: language, name: language
                                                        }))
                                                }
                                                onChange={(_, languageOption) => {
                                                    if (languageOption.filter(o => !!o?.id).length) {
                                                        updatesReservation({
                                                            language: languageOption.map((o) => o.id).join(', '),
                                                        });
                                                    } else {
                                                        updatesReservation({
                                                            language: "",
                                                        });
                                                    }
                                                }}

                                                isOptionEqualToValue={(option: { id: string, name: string }, value: { id: string, name: string }) => option.id.toUpperCase() === value.id.toUpperCase()}
                                                getOptionLabel={(option: { id: string, name: string }) => `${option.id}`}
                                                sx={{backgroundColor: 'white'}}
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={'Language'}
                                                            InputLabelProps={{shrink: true}}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                        >
                                            <FormControlLabel
                                                disabled={disabled}
                                                control={<Checkbox checked={reservation?.stroller ?? false} onChange={
                                                    (_, checked) => {
                                                        updatesReservation({
                                                            stroller: checked
                                                        });
                                                    }}/>} label="Stroller/Wheelchair"/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                        >
                                            <Divider/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                            <Typography
                                                fontWeight={'bold'}>
                                                Option
                                            </Typography>
                                        </Grid>

                                        {
                                            options.concat(otherOptions).map((option, idx) => (
                                                <>
                                                    {
                                                        idx > 0
                                                            ? (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={2}
                                                                >
                                                                </Grid>
                                                            )
                                                            : null
                                                    }
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={8}
                                                        key={option.option}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            label={'Option'}
                                                            value={option.option}
                                                            InputLabelProps={{shrink: true}}
                                                            sx={{backgroundColor: 'white'}}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={2}
                                                        key={option.option+'value'}
                                                    >
                                                        <TextField
                                                            disabled={disabled || !!reservation?.canceledAt}
                                                            fullWidth
                                                            type={'number'}
                                                            label={'People'}
                                                            value={option.people}
                                                            InputLabelProps={{shrink: true}}
                                                            sx={{backgroundColor: 'white'}}
                                                            onChange={(e) => {
                                                                const value = Number.parseInt(e.target.value ? e.target.value : '0');
                                                                if (Number.isNaN(value)) return;
                                                                const reservationOptions = JSON.parse(JSON.stringify(reservation?.option ?? [])) as any[];
                                                                const cursor = reservationOptions.find((o) => {
                                                                    return o.option === option.option
                                                                });
                                                                if (cursor) {
                                                                    cursor.people = value;
                                                                } else {
                                                                    reservationOptions.push({
                                                                        option: option.option,
                                                                        people: value
                                                                    });
                                                                }
                                                                const filtered = reservationOptions.filter((o) => o.people)
                                                                updatesReservation({
                                                                    option: filtered
                                                                });
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            ))
                                        }
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                        >
                                            <Divider/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                        >
                                            <Typography
                                                fontWeight={'bold'}>
                                                Memo
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={10}
                                        >
                                            <TextField
                                                disabled={disabled || !!reservation?.canceledAt}
                                                fullWidth
                                                multiline
                                                minRows={5}
                                                label={'Memo'}
                                                value={reservation?.memo}
                                                InputLabelProps={{shrink: true}}
                                                onChange={(event) => {
                                                    updatesReservation({
                                                        memo: event.target.value,
                                                    });
                                                }}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>

                        {
                            !disabled && openPreview && reservation?.reference?.mail?.reserve.messageId
                                ? (
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                maxHeight: '75vh',
                                                overflowY: 'auto',
                                            }}
                                        >
                                            <MailPreview messageId={reservation.reference.mail.reserve.messageId}/>
                                        </Box>
                                    </Grid>
                                )
                                : null
                        }
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
    // !!reservation && !!agencies && !!nationalities && !!products && !!pickupGroup ?  : null
}
