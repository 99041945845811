import ReactDOM from "react-dom/client"

// PWA
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { SnackbarProvider } from "notistack"
import App from "./App"
import { DrawerContextProvider } from "./context/drawer"
import { AuthProvider } from "./hooks/auth"
import { BaseProvider } from "./hooks/base"
import theme from "./theme"

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <BaseProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DrawerContextProvider>
                            <SnackbarProvider maxSnack={3}>
                                <App />
                            </SnackbarProvider>
                        </DrawerContextProvider>
                    </LocalizationProvider>
                </BaseProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </ThemeProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
reportWebVitals(console.log)
